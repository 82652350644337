import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText, Tooltip, CircularProgress, Chip, ListSubheader,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icon from '@material-ui/icons';
import { FixedSizeList } from 'react-window';
import { MenuProps } from '@material-ui/core/Menu';
import { PopoverOrigin } from '@material-ui/core/Popover';

import { UnitNightList, UnitNightListInput, UnitList, DateList, Holiday, ReblRuleset } from '../../types';
import {useAddDateListMutation, useAddUnitListMutation, useCreateUnitNightListMutation, useUpdateUnitNightListMutation} from "../../store";

interface UnitNightListFormProps {
    unitNightList?: UnitNightList;
    initialValues?: UnitNightList;
    unitLists: UnitList[];
    isLoadingUnitLists: boolean;
    refetchUnitLists: () => void;
    dateLists: DateList[];
    isLoadingDateLists: boolean;
    refetchDateLists: () => void;
    onCancel: () => void;
    holidays?: Holiday[];
    isLoadingHolidays: boolean;
    reblRules?: ReblRuleset[];
    isLoadingReblRules: boolean;
}

// Add the memoized component at the top level
const VirtualizedMenuItems = React.memo(({ 
    items,
    onSelect,
    itemType,
    searchTerm = '',
    style = {}
}: { 
    items: Array<{ id: number; name?: string; title?: string; description?: string; creator_email?: string }>;
    onSelect: (value: number) => void;
    itemType: 'unit' | 'date' | 'rebl' | 'event';
    searchTerm?: string;
    style?: React.CSSProperties;
}) => {
    const itemHeight = 48;
    
    const getFilteredItems = () => {
        const search = searchTerm.toLowerCase();
        if (!search) return items;
        
        switch (itemType) {
            case 'unit':
            case 'date':
                return items.filter(item => 
                    item.name?.toLowerCase().includes(search) ||
                    item.description?.toLowerCase().includes(search)
                );
            case 'rebl':
                return items.filter(item => 
                    item.id.toString().toLowerCase().includes(search) ||
                    item.creator_email?.toLowerCase().includes(search)
                );
            case 'event':
                return items.filter(item => 
                    item.title?.toLowerCase().includes(search) ||
                    item.name?.toLowerCase().includes(search)
                );
            default:
                return items;
        }
    };

    const filteredItems = getFilteredItems();
    
    const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const item = filteredItems[index];
        let label = '';
        
        switch (itemType) {
            case 'unit':
            case 'date':
                label = item.name || '';
                break;
            case 'rebl':
                label = `#${item.id} - ${item.creator_email}`;
                break;
            case 'event':
                label = item.title || item.name || '';
                break;
        }

        return (
            <MenuItem 
                key={item.id}
                value={item.id}
                onClick={() => onSelect(item.id)}
                style={{ ...style, ...style }}
            >
                {label}
            </MenuItem>
        );
    };

    return (
        <FixedSizeList
            height={Math.min(filteredItems.length * itemHeight, 300)}
            width="100%"
            itemCount={filteredItems.length}
            itemSize={itemHeight}
        >
            {Row}
        </FixedSizeList>
    );
});

VirtualizedMenuItems.displayName = 'VirtualizedMenuItems';

export const UnitNightListForm: React.FC<UnitNightListFormProps> = ({
    unitNightList,
    initialValues,
    unitLists,
    isLoadingUnitLists,
    refetchUnitLists,
    dateLists,
    isLoadingDateLists,
    refetchDateLists,
    onCancel,
    holidays,
    isLoadingHolidays,
    reblRules,
    isLoadingReblRules,
}) => {
    const [formData, setFormData] = useState<UnitNightListInput>({
        name: '',
        description: '',
        admin_only: false,
        included_unit_list_ids: [],
        excluded_unit_list_ids: [],
        stay_date_list_id: null,
        blackout_date_list_id: null,
        include_events: false,
        included_event_ids: [],
        exclude_events: false,
        excluded_event_ids: [],
        included_rebl_rule_ids: [],
        excluded_rebl_rule_ids: [],
    });

    const [errorMessage, setErrorMessage] = useState<string>();
    // Helper to get all events from holidays
    const allEvents = React.useMemo(() => {
        if (!holidays) return [];
        return holidays.flatMap(holiday => 
            holiday.holiday_events.map(event => ({
                ...event,
                holidayName: holiday.name
            }))
        );
    }, [holidays]);

    const newUnitList: Omit<UnitList, 'id'> = {
        name: formData.name,
        admin_only: false,
        description: formData.description,
        source: null,
        source_values: null,
    };

    const newDateList: Omit<DateList, 'id'> = {
        name: formData.name,
        admin_only: false,
        description: formData.description,
        source: "manual",
        stay_date_start: null,
        stay_date_end: null,
        days_out_start: null,
        days_out_end: null,
        sunday_checked: true,
        monday_checked: true,
        tuesday_checked: true,
        wednesday_checked: true,
        thursday_checked: true,
        friday_checked: true,
        saturday_checked: true,
    }

    const [createUnitList] = useAddUnitListMutation();
    const [createDateList] = useAddDateListMutation();
    // Add loading states
    const [isCreatingIncludedList, setIsCreatingIncludedList] = useState(false);
    const [isCreatingExcludedList, setIsCreatingExcludedList] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [createUnitNightList] = useCreateUnitNightListMutation();
    const [updateUnitNightList] = useUpdateUnitNightListMutation();

    // Add search states for included and excluded lists
    const [includedUnitListSearchTerm, setIncludedUnitListSearchTerm] = useState('');
    const [excludedUnitListSearchTerm, setExcludedUnitListSearchTerm] = useState('');
    const [includedDateListSearchTerm, setIncludedDateListSearchTerm] = useState('');
    const [excludedDateListSearchTerm, setExcludedDateListSearchTerm] = useState('');
    const [includedReblRuleSearchTerm, setIncludedReblRuleSearchTerm] = useState('');
    const [excludedReblRuleSearchTerm, setExcludedReblRuleSearchTerm] = useState('');
    const [includedEventSearchTerm, setIncludedEventSearchTerm] = useState('');
    const [excludedEventSearchTerm, setExcludedEventSearchTerm] = useState('');

    // Add filter functions
    const filteredIncludedUnitLists = unitLists?.filter(list => 
        list.name.toLowerCase().includes(includedUnitListSearchTerm.toLowerCase()) ||
        list.description?.toLowerCase().includes(includedUnitListSearchTerm.toLowerCase())
    );

    const filteredExcludedUnitLists = unitLists?.filter(list => 
        list.name.toLowerCase().includes(excludedUnitListSearchTerm.toLowerCase()) ||
        list.description?.toLowerCase().includes(excludedUnitListSearchTerm.toLowerCase())
    );

    const filteredDateLists = dateLists?.filter(list => 
        list.name.toLowerCase().includes(includedDateListSearchTerm.toLowerCase()) ||
        list.description?.toLowerCase().includes(includedDateListSearchTerm.toLowerCase())
    );

    const filteredReblRules = reblRules?.filter(rule => 
        rule.id.toString().toLowerCase().includes(includedReblRuleSearchTerm.toLowerCase()) ||
        rule.creator_email.toLowerCase().includes(includedReblRuleSearchTerm.toLowerCase())
    );

    const filteredEvents = holidays?.flatMap(holiday => 
        holiday.holiday_events.filter(event => 
            event.title.toLowerCase().includes(includedEventSearchTerm.toLowerCase()) ||
            holiday.name.toLowerCase().includes(includedEventSearchTerm.toLowerCase())
        )
    );

    // Update the handlers to show loading state
    const handleCreateIncludedList = async () => {
        setIsCreatingIncludedList(true);
        const includedUnitList = {
            ...newUnitList,
            name: `${formData.name} - Included Units`,
            description: `Units included in promotion: ${formData.name}`
        };

        try {
            const newUnitListID = await createUnitList(includedUnitList).unwrap();
            console.log(newUnitListID);
            refetchUnitLists();
            setFormData(prev => ({
                ...prev,
                included_unit_list_ids: [...(prev.included_unit_list_ids || []), newUnitListID]
            }));
        } catch (error) {
            console.error('Failed to create included unit list:', error);
        } finally {
            setIsCreatingIncludedList(false);
        }
    };

    const handleCreateExcludedList = async () => {
        setIsCreatingExcludedList(true);
        const excludedUnitList = {
            ...newUnitList,
            name: `${formData.name} - Excluded Units`,
            description: `Units excluded from promotion: ${formData.name}`
        };

        try {
            const newUnitListID = await createUnitList(excludedUnitList).unwrap();
            console.log(newUnitListID);
            refetchUnitLists();
            setFormData(prev => ({
                ...prev,
                excluded_unit_list_ids: [...(prev.excluded_unit_list_ids || []), newUnitListID]
            }));
        } catch (error) {
            console.error('Failed to create excluded unit list:', error);
        } finally {
            setIsCreatingExcludedList(false);
        }
    };

    const [isCreatingStayDateList, setIsCreatingStayDateList] = useState(false);
    const [isCreatingBlackoutDateList, setIsCreatingBlackoutDateList] = useState(false);

    // Add handlers for creating date lists
    const handleCreateStayDateList = async () => {
        setIsCreatingStayDateList(true);
        const stayDateList = {
            ...newDateList,
            name: `${formData.name} - Stay Dates`,
            description: `Stay dates for promotion: ${formData.name}`
        };

        try {
            const newDateListID = await createDateList(stayDateList).unwrap();
            refetchDateLists();
            setFormData(prev => ({
                ...prev,
                stay_date_list_id: newDateListID
            }));
        } catch (error) {
            console.error('Failed to create stay date list:', error);
        } finally {
            setIsCreatingStayDateList(false);
        }
    };

    const handleCreateBlackoutDateList = async () => {
        setIsCreatingBlackoutDateList(true);
        const blackoutDateList = {
            ...newDateList,
            name: `${formData.name} - Blackout Dates`,
            description: `Blackout dates for promotion: ${formData.name}`
        };

        try {
            const newDateListID = await createDateList(blackoutDateList).unwrap();
            refetchDateLists();
            setFormData(prev => ({
                ...prev,
                blackout_date_list_id: newDateListID
            }));
        } catch (error) {
            console.error('Failed to create blackout date list:', error);
        } finally {
            setIsCreatingBlackoutDateList(false);
        }
    };

    useEffect(() => {
        const source = unitNightList || initialValues;
        if (source) {
            setFormData({
                name: source.name || '',
                description: source.description || '',
                admin_only: source.admin_only || false,
                included_unit_list_ids: source.included_unit_list_ids || [],
                excluded_unit_list_ids: source.excluded_unit_list_ids || [],
                stay_date_list_id: !!source.stay_date_list_id ? source.stay_date_list_id : null,
                blackout_date_list_id: !!source.blackout_date_list_id ? source.blackout_date_list_id : null,
                include_events: source.include_events || false,
                included_event_ids: source.included_event_ids || [],
                exclude_events: source.exclude_events || false,
                excluded_event_ids: source.excluded_event_ids || [],
                included_rebl_rule_ids: source.included_rebl_rule_ids || [],
                excluded_rebl_rule_ids: source.excluded_rebl_rule_ids || [],
            });
        }
    }, [unitNightList, initialValues]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage(null);
        setIsSubmitting(true);
        
        // Ensure all required fields are present
        const submitData: UnitNightListInput = {
            name: formData.name.trim(),
            description: formData.description || '',
            admin_only: formData.admin_only || false,
            included_unit_list_ids: formData.included_unit_list_ids || [],
            excluded_unit_list_ids: formData.excluded_unit_list_ids || [],
            included_rebl_rule_ids: formData.included_rebl_rule_ids || [],
            excluded_rebl_rule_ids: formData.excluded_rebl_rule_ids || [],
            stay_date_list_id: formData.stay_date_list_id || null,
            blackout_date_list_id: formData.blackout_date_list_id || null,
            include_events: formData.include_events || false,
            included_event_ids: formData.included_event_ids || [],
            exclude_events: formData.exclude_events || false,
            excluded_event_ids: formData.excluded_event_ids || [],
        };
        
        try {
            if (unitNightList?.id) {
                await updateUnitNightList({ 
                    id: unitNightList.id, 
                    data: submitData 
                }).unwrap();
            } else {
                await createUnitNightList(submitData).unwrap();
            }
            onCancel(); // Close modal on success
        } catch (err) {
            console.error('Failed to save unit night list:', err);
            setErrorMessage(
                unitNightList?.id 
                    ? 'Failed to update unit night list. Please try again.' 
                    : 'Failed to create unit night list. Please try again.'
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    // Add handlers for REBL rules
    const handleIncludedReblRulesChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = e.target.value as number[];
        setFormData(prev => ({
            ...prev,
            included_rebl_rule_ids: newValue
        }));
    };

    const handleExcludedReblRulesChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = e.target.value as number[];
        setFormData(prev => ({
            ...prev,
            excluded_rebl_rule_ids: newValue
        }));
    };

    // Add handlers for events
    const handleIncludedEventsChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = e.target.value as number[];
        setFormData(prev => ({
            ...prev,
            included_event_ids: newValue
        }));
    };

    const handleExcludedEventsChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = e.target.value as number[];
        setFormData(prev => ({
            ...prev,
            excluded_event_ids: newValue
        }));
    };

    // Update the MenuProps configuration with proper types
    const menuProps: Partial<MenuProps> = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        } as PopoverOrigin,
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        } as PopoverOrigin,
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: 300
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ position: 'relative', paddingRight: '24px' }}>
                <h4 style={{ marginBottom: '15px' }}>
                    {unitNightList ? 'Edit Unit Night List' : 'Create Unit Night List'}
                </h4>
                
                <Button
                    onClick={onCancel}
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        minWidth: 'auto',
                        padding: '6px'
                    }}
                >
                    <Icon.Close />
                </Button>
            </div>
            
            <Grid container spacing={3}>
                {errorMessage && (
                    <Grid item xs={12}>
                        <Alert 
                            severity="error" 
                            onClose={() => setErrorMessage?.(null)}
                        >
                            {errorMessage}
                        </Alert>
                    </Grid>
                )}
                
                {/* Name and Admin Only on same row */}
                <Grid item container spacing={2} alignItems="center">
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            required
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.admin_only}
                                    onChange={(e) => setFormData({ ...formData, admin_only: e.target.checked })}
                                />
                            }
                            label="Admin Only"
                        />
                    </Grid>
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                    <TextField
                        label="Description"
                        fullWidth
                        value={formData.description}
                        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                        multiline
                        rows={2}
                        variant="outlined"
                        size="small"
                    />
                </Grid>

                {/* Unit Lists side by side */}
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                <InputLabel style={{ position: 'relative' }}>Included Unit Lists</InputLabel>
                                <Button
                                    size="small"
                                    startIcon={isCreatingIncludedList ? <CircularProgress size={16} /> : <Icon.Add />}
                                    onClick={handleCreateIncludedList}
                                    disabled={!formData.name.trim() || isCreatingIncludedList}
                                >
                                    New List
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                    {(formData.included_unit_list_ids || []).map((id) => {
                                        const list = unitLists?.find(l => l.id === id);
                                        return (
                                            <Chip
                                                key={id}
                                                label={list?.name || id}
                                                onDelete={() => {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        included_unit_list_ids: prev.included_unit_list_ids.filter(listId => listId !== id)
                                                    }));
                                                }}
                                                size="small"
                                            />
                                        );
                                    })}
                                </div>
                                <Select
                                    value=""
                                    onChange={(e) => {
                                        const newValue = e.target.value as number;
                                        if (newValue) {
                                            setFormData(prev => ({
                                                ...prev,
                                                included_unit_list_ids: [...prev.included_unit_list_ids, newValue]
                                            }));
                                        }
                                    }}
                                    displayEmpty
                                    renderValue={() => <em>
                                        {isLoadingUnitLists ? 'Loading...' : 'Select lists to include'}
                                    </em>}
                                    disabled={isLoadingUnitLists}
                                    MenuProps={menuProps}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to filter included unit lists..."
                                            fullWidth
                                            value={includedUnitListSearchTerm}
                                            onChange={(e) => setIncludedUnitListSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filteredIncludedUnitLists
                                        ?.filter(list => !formData.included_unit_list_ids.includes(list.id))
                                        .map(list => (
                                            <MenuItem key={list.id} value={list.id}>
                                                {list.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                <InputLabel style={{ position: 'relative' }}>Excluded Unit Lists</InputLabel>
                                <Button
                                    size="small"
                                    startIcon={isCreatingExcludedList ? <CircularProgress size={16} /> : <Icon.Add />}
                                    onClick={handleCreateExcludedList}
                                    disabled={!formData.name.trim() || isCreatingExcludedList}
                                >
                                    New List
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                    {(formData.excluded_unit_list_ids || []).map((id) => {
                                        const list = unitLists?.find(l => l.id === id);
                                        return (
                                            <Chip
                                                key={id}
                                                label={list?.name || id}
                                                onDelete={() => {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        excluded_unit_list_ids: prev.excluded_unit_list_ids.filter(listId => listId !== id)
                                                    }));
                                                }}
                                                size="small"
                                            />
                                        );
                                    })}
                                </div>
                                <Select
                                    value=""
                                    onChange={(e) => {
                                        const newValue = e.target.value as number;
                                        if (newValue) {
                                            setFormData(prev => ({
                                                ...prev,
                                                excluded_unit_list_ids: [...prev.excluded_unit_list_ids, newValue]
                                            }));
                                        }
                                    }}
                                    displayEmpty
                                    renderValue={() => <em>
                                        {isLoadingUnitLists ? 'Loading...' : 'Select lists to exclude'}
                                    </em>}
                                    disabled={isLoadingUnitLists}
                                    MenuProps={menuProps}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to filter excluded unit lists..."
                                            fullWidth
                                            value={excludedUnitListSearchTerm}
                                            onChange={(e) => setExcludedUnitListSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filteredExcludedUnitLists?.map(list => (
                                        <MenuItem key={list.id} value={list.id}>
                                            {list.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Date Lists side by side */}
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                <InputLabel style={{ position: 'relative' }}>Stay Date List</InputLabel>
                                <Button
                                    size="small"
                                    startIcon={isCreatingStayDateList ? <CircularProgress size={16} /> : <Icon.Add />}
                                    onClick={handleCreateStayDateList}
                                    disabled={!formData.name.trim() || isCreatingStayDateList}
                                >
                                    New List
                                </Button>
                            </div>
                            <Select
                                value={!!formData.stay_date_list_id ? formData.stay_date_list_id : ''}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    stay_date_list_id: e.target.value === '' ? null : e.target.value as number
                                }))}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected === '') {
                                        return <em>{isLoadingDateLists ? 'Loading...' : 'Select a stay date list'}</em>;
                                    }
                                    const selectedList = dateLists?.find(list => list.id === selected);
                                    return selectedList ? selectedList.name : 'Unknown list';
                                }}
                                disabled={isLoadingDateLists}
                                MenuProps={menuProps}
                            >
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Type to filter stay date lists..."
                                        fullWidth
                                        value={includedDateListSearchTerm}
                                        onChange={(e) => setIncludedDateListSearchTerm(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Escape') {
                                                e.stopPropagation();
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </ListSubheader>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {dateLists
                                    ?.filter(list => 
                                        list.name.toLowerCase().includes(includedDateListSearchTerm.toLowerCase()) ||
                                        list.description?.toLowerCase().includes(includedDateListSearchTerm.toLowerCase())
                                    )
                                    .map(list => (
                                        <MenuItem key={list.id} value={list.id}>
                                            {list.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                <InputLabel style={{ position: 'relative' }}>Blackout Date List</InputLabel>
                                <Button
                                    size="small"
                                    startIcon={isCreatingBlackoutDateList ? <CircularProgress size={16} /> : <Icon.Add />}
                                    onClick={handleCreateBlackoutDateList}
                                    disabled={!formData.name.trim() || isCreatingBlackoutDateList}
                                >
                                    New List
                                </Button>
                            </div>
                            <Select
                                value={formData.blackout_date_list_id || ''}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    blackout_date_list_id: e.target.value as number
                                }))}
                                displayEmpty
                                renderValue={() => <em>
                                    {isLoadingDateLists ? 'Loading...' : 'Select a blackout date list'}
                                </em>}
                                disabled={isLoadingDateLists}
                                MenuProps={menuProps}
                            >
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Type to filter blackout date lists..."
                                        fullWidth
                                        value={excludedDateListSearchTerm}
                                        onChange={(e) => setExcludedDateListSearchTerm(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Escape') {
                                                e.stopPropagation();
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </ListSubheader>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {dateLists
                                    ?.filter(list => 
                                        list.name.toLowerCase().includes(excludedDateListSearchTerm.toLowerCase()) ||
                                        list.description?.toLowerCase().includes(excludedDateListSearchTerm.toLowerCase())
                                    )
                                    .map(list => (
                                        <MenuItem key={list.id} value={list.id}>
                                            {list.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* REBL Rules */}
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                <InputLabel style={{ position: 'relative' }}>Included REBL Rules</InputLabel>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                    {formData.included_rebl_rule_ids.map((id) => (
                                        <Chip
                                            key={id}
                                            label={`#${id} - ${reblRules?.find(r => r.id === id)?.creator_email || 'unknown'}`}
                                            onDelete={() => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    included_rebl_rule_ids: prev.included_rebl_rule_ids.filter(rId => rId !== id)
                                                }));
                                            }}
                                            size="small"
                                        />
                                    ))}
                                </div>
                                <Select
                                    multiple
                                    value={formData.included_rebl_rule_ids || []}
                                    onChange={handleIncludedReblRulesChange}
                                    displayEmpty
                                    renderValue={() => <em>
                                        {isLoadingReblRules ? 'Loading...' : 'Select rules to include'}
                                    </em>}
                                    disabled={isLoadingReblRules}
                                    MenuProps={menuProps}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to filter included REBL rules..."
                                            fullWidth
                                            value={includedReblRuleSearchTerm}
                                            onChange={(e) => setIncludedReblRuleSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <VirtualizedMenuItems
                                        items={reblRules?.filter(rule => !formData.included_rebl_rule_ids.includes(rule.id)) || []}
                                        onSelect={(id) => {
                                            setFormData(prev => ({
                                                ...prev,
                                                included_rebl_rule_ids: [...prev.included_rebl_rule_ids, id]
                                            }));
                                        }}
                                        itemType="rebl"
                                        searchTerm={includedReblRuleSearchTerm}
                                    />
                                </Select>
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                <InputLabel style={{ position: 'relative' }}>Excluded REBL Rules</InputLabel>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                    {formData.excluded_rebl_rule_ids.map((id) => (
                                        <Chip
                                            key={id}
                                            label={`#${id} - ${reblRules?.find(r => r.id === id)?.creator_email || 'unknown'}`}
                                            onDelete={() => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    excluded_rebl_rule_ids: prev.excluded_rebl_rule_ids.filter(rId => rId !== id)
                                                }));
                                            }}
                                            size="small"
                                            color="secondary"
                                        />
                                    ))}
                                </div>
                                <Select
                                    multiple
                                    value={formData.excluded_rebl_rule_ids || []}
                                    onChange={handleExcludedReblRulesChange}
                                    displayEmpty
                                    renderValue={() => <em>
                                        {isLoadingReblRules ? 'Loading...' : 'Select rules to exclude'}
                                    </em>}
                                    disabled={isLoadingReblRules}
                                    MenuProps={menuProps}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to filter excluded REBL rules..."
                                            fullWidth
                                            value={excludedReblRuleSearchTerm}
                                            onChange={(e) => setExcludedReblRuleSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <VirtualizedMenuItems
                                        items={reblRules?.filter(rule => !formData.excluded_rebl_rule_ids.includes(rule.id)) || []}
                                        onSelect={(id) => {
                                            setFormData(prev => ({
                                                ...prev,
                                                excluded_rebl_rule_ids: [...prev.excluded_rebl_rule_ids, id]
                                            }));
                                        }}
                                        itemType="rebl"
                                        searchTerm={excludedReblRuleSearchTerm}
                                    />
                                </Select>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Include Events */}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.include_events}
                                onChange={(e) => {
                                    setFormData({ 
                                        ...formData,
                                        include_events: e.target.checked,
                                        included_event_ids: [] 
                                    });
                                }}
                            />
                        }
                        label="Include Events"
                    />
                    {formData.include_events && (
                        <FormControl fullWidth style={{ marginTop: '8px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                    {formData.included_event_ids?.map((eventId) => {
                                        const event = allEvents.find(e => e.id === eventId);
                                        return (
                                            <Tooltip 
                                                key={eventId} 
                                                title={`${event?.holidayName}: ${event?.title}`}
                                            >
                                                <Chip
                                                    label={event?.title || eventId}
                                                    onDelete={() => {
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            included_event_ids: prev.included_event_ids?.filter(id => id !== eventId)
                                                        }));
                                                    }}
                                                    size="small"
                                                />
                                            </Tooltip>
                                        );
                                    })}
                                </div>
                                <Select
                                    multiple
                                    value={formData.included_event_ids || []}
                                    onChange={handleIncludedEventsChange}
                                    displayEmpty
                                    renderValue={() => <em>
                                        {isLoadingHolidays ? 'Loading...' : formData.included_event_ids?.length === 0 
                                            ? 'All events will be included' 
                                            : 'Select events to include'}
                                    </em>}
                                    disabled={isLoadingHolidays}
                                    MenuProps={menuProps}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to filter included events..."
                                            fullWidth
                                            value={includedEventSearchTerm}
                                            onChange={(e) => setIncludedEventSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {holidays?.map(holiday => {
                                        // Filter events for this holiday
                                        const matchingEvents = holiday.holiday_events
                                            .filter(event => 
                                                !formData.included_event_ids?.includes(event.id) &&
                                                (event.title.toLowerCase().includes(includedEventSearchTerm.toLowerCase()) ||
                                                holiday.name.toLowerCase().includes(includedEventSearchTerm.toLowerCase()))
                                            );
                                        
                                        // Only render this holiday section if it has matching events or its name matches the search
                                        return matchingEvents.length > 0 || holiday.name.toLowerCase().includes(includedEventSearchTerm.toLowerCase()) ? [
                                            <ListSubheader 
                                                key={`header-${holiday.id}`}
                                                style={{
                                                    backgroundColor: 'white',
                                                    position: 'sticky',
                                                    top: -8,
                                                    zIndex: 1
                                                }}
                                            >
                                                {holiday.name}
                                            </ListSubheader>,
                                            ...matchingEvents.map(event => (
                                                <MenuItem 
                                                    key={event.id} 
                                                    value={event.id}
                                                    style={{ paddingLeft: '32px' }}
                                                >
                                                    {event.title}
                                                </MenuItem>
                                            ))
                                        ] : null;
                                    }).filter(Boolean)}
                                </Select>
                            </div>
                            <FormHelperText>
                                {formData.included_event_ids?.length === 0
                                    ? "No events selected - all events will be included" 
                                    : `${formData.included_event_ids.length} events selected`}
                            </FormHelperText>
                        </FormControl>
                    )}
                </Grid>

                {/* Exclude Events */}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.exclude_events}
                                onChange={(e) => {
                                    setFormData({ 
                                        ...formData, 
                                        exclude_events: e.target.checked,
                                        excluded_event_ids: [] 
                                    });
                                }}
                            />
                        }
                        label="Exclude Events"
                    />
                    {formData.exclude_events && (
                        <FormControl fullWidth style={{ marginTop: '8px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', margin: '8px 0' }}>
                                    {formData.excluded_event_ids?.map((eventId) => {
                                        const event = allEvents.find(e => e.id === eventId);
                                        return (
                                            <Tooltip 
                                                key={eventId} 
                                                title={`${event?.holidayName}: ${event?.title}`}
                                            >
                                                <Chip
                                                    label={event?.title || eventId}
                                                    onDelete={() => {
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            excluded_event_ids: prev.excluded_event_ids?.filter(id => id !== eventId)
                                                        }));
                                                    }}
                                                    size="small"
                                                />
                                            </Tooltip>
                                        );
                                    })}
                                </div>
                                <Select
                                    multiple
                                    value={formData.excluded_event_ids || []}
                                    onChange={handleExcludedEventsChange}
                                    displayEmpty
                                    renderValue={() => <em>
                                        {isLoadingHolidays ? 'Loading...' : formData.excluded_event_ids?.length === 0 
                                            ? 'No events excluded' 
                                            : 'Select events to exclude'}
                                    </em>}
                                    disabled={isLoadingHolidays}
                                    MenuProps={menuProps}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to filter excluded events..."
                                            fullWidth
                                            value={excludedEventSearchTerm}
                                            onChange={(e) => setExcludedEventSearchTerm(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== 'Escape') {
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {holidays?.map(holiday => {
                                        // Filter events for this holiday
                                        const matchingEvents = holiday.holiday_events
                                            .filter(event => 
                                                !formData.excluded_event_ids?.includes(event.id) &&
                                                (event.title.toLowerCase().includes(excludedEventSearchTerm.toLowerCase()) ||
                                                holiday.name.toLowerCase().includes(excludedEventSearchTerm.toLowerCase()))
                                            );
                                        
                                        // Only render this holiday section if it has matching events or its name matches the search
                                        return matchingEvents.length > 0 || holiday.name.toLowerCase().includes(excludedEventSearchTerm.toLowerCase()) ? [
                                            <ListSubheader 
                                                key={`header-${holiday.id}`}
                                                style={{
                                                    backgroundColor: 'white',
                                                    position: 'sticky',
                                                    top: -8,
                                                    zIndex: 1
                                                }}
                                            >
                                                {holiday.name}
                                            </ListSubheader>,
                                            ...matchingEvents.map(event => (
                                                <MenuItem 
                                                    key={event.id} 
                                                    value={event.id}
                                                    style={{ paddingLeft: '32px' }}
                                                >
                                                    {event.title}
                                                </MenuItem>
                                            ))
                                        ] : null;
                                    }).filter(Boolean)}
                                </Select>
                            </div>
                            <FormHelperText>
                                {formData.excluded_event_ids?.length === 0
                                    ? "No events selected - no events will be excluded" 
                                    : `${formData.excluded_event_ids.length} events selected`}
                            </FormHelperText>
                        </FormControl>
                    )}
                </Grid>

                {/* Action Buttons */}
                <Grid item xs={12}>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"
                        disabled={isSubmitting}
                        startIcon={isSubmitting && <CircularProgress size={20} />}
                    >
                        {unitNightList ? 'Update' : 'Create'}
                    </Button>
                    <Button 
                        onClick={onCancel} 
                        variant="outlined" 
                        style={{ marginLeft: 8 }}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}; 