import React, { useState } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    TextField,
    TableSortLabel,
    CircularProgress,
    TablePagination,
    Modal,
    Chip,
    Menu,
    MenuItem,
    Checkbox,
    Typography,
    ListSubheader,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {DateList, UnitList, UnitNightList, ReblRuleset, Holiday} from '../../types';
import {useGetAllReblRulesQuery, useGetAllUnitNightListsQuery, useGetHolidaysTableQuery} from '../../store';
import { UnitNightListForm } from './UnitNightListForm';
import { format } from 'date-fns';
import {UiUtils} from "../../utils";

interface UnitNightListsTableProps {
    unitLists: UnitList[],
    isLoadingUnitLists: boolean,
    refetchUnitLists: () => void,
    dateLists: DateList[],
    isLoadingDateLists: boolean,
    searchTerm: string,
    setSearchTerm: (value: string) => void;
    refetchDateLists: () => void,
    onTabChange: (tabId: string, searchTerm?: string) => void;
}

type SortField = 
    | 'id' 
    | 'name' 
    | 'included_units_count'
    | 'excluded_units_count'
    | 'included_dates_count' 
    | 'excluded_dates_count'
    | 'included_unit_nights_count'
    | 'excluded_unit_nights_count'
    | 'creator'
    | 'created_at'
    | 'updater'
    | 'updated_at'
    | 'stay_date_list_id'
    | 'blackout_date_list_id'
    | 'unit_night_count'
    | 'excluded_event_ids'
    | 'included_event_ids'
    | 'included_rebl_rule_ids'
    | 'excluded_rebl_rule_ids';
type SortDirection = 'asc' | 'desc';

interface ColumnConfig {
    id: string;
    label: string;
    required?: boolean;
    defaultVisible?: boolean;
    minWidth?: string;
    sortField?: SortField;
}

const COLUMNS: ColumnConfig[] = [
    { id: 'id', label: 'ID', required: true, sortField: 'id', minWidth: '50px' },
    { id: 'name', label: 'Name', defaultVisible: true, sortField: 'name', minWidth: '150px' },
    { id: 'included_units', label: 'Included Units', defaultVisible: true, sortField: 'included_units_count', minWidth: '200px' },
    { id: 'excluded_units', label: 'Excluded Units', defaultVisible: true, sortField: 'excluded_units_count', minWidth: '200px' },
    { id: 'stay_dates', label: 'Stay Dates', defaultVisible: true, sortField: 'stay_date_list_id', minWidth: '150px' },
    { id: 'blackout_dates', label: 'Blackout Dates', defaultVisible: false, sortField: 'blackout_date_list_id', minWidth: '150px' },
    { id: 'unit_nights', label: 'Unit Nights', defaultVisible: true, sortField: 'unit_night_count', minWidth: '120px' },
    { id: 'creator', label: 'Created By', defaultVisible: true, sortField: 'creator', minWidth: '100px' },
    { id: 'created_at', label: 'Created At', defaultVisible: false, sortField: 'created_at', minWidth: '150px' },
    { id: 'updater', label: 'Updated By', defaultVisible: false, sortField: 'updater', minWidth: '100px' },
    { id: 'updated_at', label: 'Updated At', defaultVisible: false, sortField: 'updated_at', minWidth: '150px' },
    { id: 'exclude_events', label: 'Exclude Events', defaultVisible: false, minWidth: '120px' },
    { id: 'excluded_event_ids', label: 'Excluded Event IDs', defaultVisible: false, sortField: 'excluded_event_ids', minWidth: '150px' },
    { id: 'included_rebl_rules', label: 'Included Rules', defaultVisible: false, sortField: 'included_rebl_rule_ids', minWidth: '150px' },
    { id: 'excluded_rebl_rules', label: 'Excluded Rules', defaultVisible: false, sortField: 'excluded_rebl_rule_ids', minWidth: '150px' },
    { id: 'include_events', label: 'Include Events', defaultVisible: false, minWidth: '120px' },
    { id: 'included_event_ids', label: 'Included Events', defaultVisible: false, sortField: 'included_event_ids', minWidth: '150px' },
    { id: 'actions', label: 'Actions', required: true, minWidth: '100px' },
];

const formatLargeNumber = (num: number): string => {
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
        return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toString();
};

const formatNumberWithCommas = (num: number): string => {
    return num.toLocaleString();
};

// Add helper function to format creator name
const formatCreatorName = (email: string) => {
    return email ? email.split('@')[0] : '-';
};

// Add helper function to format creation date
const formatCreationDate = (date: string | null | undefined) => {
    if (!date) return 'Creation date unknown';
    try {
        return `Created on ${format(new Date(date), 'MMM d, yyyy HH:mm:ss')}`;
    } catch (e) {
        return 'Invalid creation date';
    }
};

// Add helper to format email without domain
const formatEmail = (email: string) => {
    return email.split('@')[0];
};

// First, let's define our column groups
const COLUMN_GROUPS = [
    {
        label: 'Basic Info',
        columns: ['id', 'name']
    },
    {
        label: 'Units',
        columns: ['included_units', 'excluded_units']
    },
    {
        label: 'Dates',
        columns: ['stay_dates', 'blackout_dates']
    },
    {
        label: 'Events',
        columns: ['include_events', 'included_event_ids', 'exclude_events', 'excluded_event_ids']
    },
    {
        label: 'REBL Rules',
        columns: ['included_rebl_rules', 'excluded_rebl_rules']
    },
    {
        label: 'Stats',
        columns: ['unit_nights']
    },
    {
        label: 'Metadata',
        columns: ['creator', 'created_at', 'updater', 'updated_at']
    },
    {
        label: 'Actions',
        columns: ['actions']
    }
];

export const UnitNightListsTable: React.FC<UnitNightListsTableProps> = ({
    unitLists,
    isLoadingUnitLists,
    refetchUnitLists,
    dateLists,
    isLoadingDateLists,
    refetchDateLists,
    searchTerm,
    setSearchTerm,
    onTabChange,
}) => {
    const { data: unitNightLists, isLoading, refetch } = useGetAllUnitNightListsQuery();
    const { data: holidays, isLoading: isLoadingHolidays } = useGetHolidaysTableQuery();
    const { data: reblRules, isLoading: isLoadingReblRules } = useGetAllReblRulesQuery();
    const [sortField, setSortField] = useState<SortField>('id');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [selectedUnitNightList, setSelectedUnitNightList] = useState<UnitNightList | undefined>(undefined);
    const [isCopying, setIsCopying] = useState(false);
    const classes = UiUtils.useTableStyles();

    // Add state for visible columns
    const [visibleColumns, setVisibleColumns] = useState<string[]>(() => {
        const stored = sessionStorage.getItem('unitNightListColumns');
        if (stored) {
            return JSON.parse(stored);
        }
        return COLUMNS.filter(col => col.required || col.defaultVisible).map(col => col.id);
    });

    // Add column selector menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // Add state for refetch loading
    const [isRefetching, setIsRefetching] = useState(false);

    const handleSort = (field: SortField) => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const handleCreate = () => {
        setSelectedUnitNightList(undefined);
        setIsCopying(false);
        setOpenModal(true);
    };

    const handleEdit = (unitNightList: UnitNightList) => {
        setSelectedUnitNightList(unitNightList);
        setIsCopying(false);
        setOpenModal(true);
    };

    const handleCopy = (unitNightList: UnitNightList) => {
        setSelectedUnitNightList(unitNightList);
        setIsCopying(true);
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setSelectedUnitNightList(undefined);
        setIsCopying(false);
    };

    const sortedAndFilteredLists = React.useMemo(() => {
        if (!unitNightLists) return [];

        let filtered = unitNightLists.filter(list => 
            list.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            list.description.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return filtered.sort((a, b) => {
            const aValue = a[sortField];
            const bValue = b[sortField];

            if (aValue === bValue) return 0;
            const comparison = aValue < bValue ? -1 : 1;
            return sortDirection === 'asc' ? comparison : -comparison;
        });
    }, [unitNightLists, searchTerm, sortField, sortDirection]);

    const paginatedLists = sortedAndFilteredLists.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const getUnitListName = (id: number) => {
        return unitLists?.find(list => list.id === id)?.name || `List ${id}`;
    };

    const getDateListName = (id: number) => {
        return dateLists?.find(list => list.id === id)?.name || `List ${id}`;
    };

    const handleListClick = (listId: number, listType: 'unit' | 'date') => {
        const tabId = listType === 'unit' ? 'unit_lists' : 'date_lists';
        const listName = listType === 'unit' ? getUnitListName(listId) : getDateListName(listId);
        onTabChange(tabId, listName);
    };

    const handleColumnToggle = (columnId: string) => {
        const newVisibleColumns = visibleColumns.includes(columnId)
            ? visibleColumns.filter(id => id !== columnId)
            : [...visibleColumns, columnId];

        setVisibleColumns(newVisibleColumns);
        sessionStorage.setItem('unitNightListColumns', JSON.stringify(newVisibleColumns));
    };

    // Add helper to get event details
    const getEventDetails = (eventId: number, holidays: Holiday[]) => {
        if (!holidays) return null;
        for (const holiday of holidays) {
            const event = holiday.holiday_events.find(e => e.id === eventId);
            if (event) {
                return {
                    title: event.title,
                    holidayName: holiday.name
                };
            }
        }
        return null;
    };

    // Update the getReblRuleDetails helper
    const getReblRuleDetails = (ruleId: number, rules?: ReblRuleset[]) => {
        if (!rules) return null;
        const rule = rules.find(r => r.id === ruleId);
        if (!rule) return null;
        return {
            id: rule.id,
            creator_email: formatEmail(rule.creator_email)
        };
    };

    // Update the refetch handler
    const handleRefetch = async () => {
        try {
            setIsRefetching(true);
            await refetch();
        } finally {
            // Add a small delay to prevent flickering
            setTimeout(() => {
                setIsRefetching(false);
            }, 500);
        }
    };

    return (
        <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
                <TextField
                    label="Search unit night lists"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: '450px' }}
                    InputProps={{
                        startAdornment: <Icon.Search style={{ color: 'gray', marginRight: 8 }} />,
                        endAdornment: searchTerm && (
                            <IconButton
                                size="small"
                                onClick={() => setSearchTerm('')}
                                style={{ padding: 4 }}
                            >
                                <Icon.Close fontSize="small" />
                            </IconButton>
                        )
                    }}
                />
                <Tooltip title="Add Unit Night List">
                    <IconButton
                        onClick={handleCreate}
                        size="small"
                        disabled={isLoading}
                    >
                        <Icon.Add fontSize="small"/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={isRefetching ? "Loading..." : "Refresh data"}>
                    <IconButton
                        onClick={handleRefetch}
                        disabled={isRefetching || isLoading}
                        size="small"
                    >
                        {isRefetching ? (
                            <CircularProgress size={16} />
                        ) : (
                            <Icon.Replay fontSize="small"/>
                        )}
                    </IconButton>
                </Tooltip>

                <div style={{ flexGrow: 1 }} />

                <Tooltip title="Column Settings">
                    <IconButton
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        size="small"
                    >
                        <Icon.Settings fontSize="small"/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem disabled style={{ opacity: 1 }}>
                    <Typography variant="subtitle2">Show/Hide Columns</Typography>
                </MenuItem>
                {COLUMN_GROUPS.map(group => [
                    <ListSubheader 
                        key={`header-${group.label}`}
                        style={{
                            backgroundColor: 'white',
                            lineHeight: '32px',
                            marginTop: '8px'
                        }}
                    >
                        {group.label}
                    </ListSubheader>,
                    ...group.columns.map(columnId => {
                        const column = COLUMNS.find(c => c.id === columnId);
                        if (!column) return null;
                        return (
                            <MenuItem
                                key={column.id}
                                onClick={() => !column.required && handleColumnToggle(column.id)}
                                disabled={column.required}
                            >
                                <Checkbox
                                    checked={visibleColumns.includes(column.id)}
                                    disabled={column.required}
                                    size="small"
                                />
                                {column.label}
                            </MenuItem>
                        );
                    })
                ])}
            </Menu>
            <div style={{ position: 'relative' }}>
                {(isLoading || isRefetching) && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}>
                        <CircularProgress />
                    </div>
                )}
                <TableContainer 
                    className={classes.tableContainer}
                    style={{ height: '600px', overflow: 'auto' }}
                >
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {COLUMNS.filter(col => visibleColumns.includes(col.id)).map(column => (
                                    <TableCell 
                                        key={column.id}
                                        className={`sticky-header-cell ${classes.stickyHeader}`}
                                        style={column.minWidth ? { minWidth: column.minWidth } : undefined}
                                    >
                                        {column.id === 'id' && (
                                            <TableSortLabel
                                                active={sortField === 'id'}
                                                direction={sortField === 'id' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('id')}
                                            >
                                                ID
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'name' && (
                                            <TableSortLabel
                                                active={sortField === 'name'}
                                                direction={sortField === 'name' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('name')}
                                            >
                                                Name
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'included_units' && (
                                            <TableSortLabel
                                                active={sortField === 'included_units_count'}
                                                direction={sortField === 'included_units_count' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('included_units_count')}
                                            >
                                                Included Units
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'excluded_units' && (
                                            <TableSortLabel
                                                active={sortField === 'excluded_units_count'}
                                                direction={sortField === 'excluded_units_count' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('excluded_units_count')}
                                            >
                                                Excluded Units
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'stay_dates' && (
                                            <TableSortLabel
                                                active={sortField === 'stay_date_list_id'}
                                                direction={sortField === 'stay_date_list_id' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('stay_date_list_id')}
                                            >
                                                Stay Dates
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'blackout_dates' && (
                                            <TableSortLabel
                                                active={sortField === 'blackout_date_list_id'}
                                                direction={sortField === 'blackout_date_list_id' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('blackout_date_list_id')}
                                            >
                                                Blackout Dates
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'unit_nights' && (
                                            <TableSortLabel
                                                active={sortField === 'unit_night_count'}
                                                direction={sortField === 'unit_night_count' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('unit_night_count')}
                                            >
                                                Unit Nights
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'creator' && (
                                            <TableSortLabel
                                                active={sortField === 'creator'}
                                                direction={sortField === 'creator' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('creator')}
                                            >
                                                Created By
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'created_at' && (
                                            <TableSortLabel
                                                active={sortField === 'created_at'}
                                                direction={sortField === 'created_at' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('created_at')}
                                            >
                                                Created At
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'updater' && (
                                            <TableSortLabel
                                                active={sortField === 'updater'}
                                                direction={sortField === 'updater' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('updater')}
                                            >
                                                Updated By
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'updated_at' && (
                                            <TableSortLabel
                                                active={sortField === 'updated_at'}
                                                direction={sortField === 'updated_at' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('updated_at')}
                                            >
                                                Updated At
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'exclude_events' && (
                                            <TableSortLabel
                                                active={sortField === 'excluded_event_ids'}
                                                direction={sortField === 'excluded_event_ids' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('excluded_event_ids')}
                                            >
                                                Exclude Events
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'excluded_event_ids' && (
                                            <TableSortLabel
                                                active={sortField === 'excluded_event_ids'}
                                                direction={sortField === 'excluded_event_ids' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('excluded_event_ids')}
                                            >
                                                Excluded Event IDs
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'included_rebl_rules' && 'Included Rules'}
                                        {column.id === 'excluded_rebl_rules' && 'Excluded Rules'}
                                        {column.id === 'include_events' && (
                                            <TableSortLabel
                                                active={sortField === 'included_event_ids'}
                                                direction={sortField === 'included_event_ids' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('included_event_ids')}
                                            >
                                                Include Events
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'included_event_ids' && (
                                            <TableSortLabel
                                                active={sortField === 'included_event_ids'}
                                                direction={sortField === 'included_event_ids' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('included_event_ids')}
                                            >
                                                Included Events
                                            </TableSortLabel>
                                        )}
                                        {column.id === 'actions' && (
                                            'Actions'
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedLists.map((list) => (
                                <TableRow key={list.id} className={classes.tableRow}>
                                    {COLUMNS.filter(col => visibleColumns.includes(col.id)).map((column) => (
                                        <TableCell key={`${list.id}-${column.id}`}>
                                            {column.id === 'id' && list.id}
                                            {column.id === 'name' && (
                                                <Tooltip title={list.description || 'No description provided'}>
                                                    <span>{list.name}</span>
                                                </Tooltip>
                                            )}
                                            {column.id === 'included_units' && (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                    <div style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                        {list.unit_count} units
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                                                        {list.included_unit_list_ids.map(id => (
                                                            <Chip
                                                                key={id}
                                                                label={getUnitListName(id)}
                                                                size="small"
                                                                onClick={() => handleListClick(id, 'unit')}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {column.id === 'excluded_units' && (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                    {list.excluded_unit_list_ids?.length > 0 && (
                                                        <div style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                            {list.excluded_unit_count} excluded
                                                        </div>
                                                    )}
                                                    <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                                                        {list.excluded_unit_list_ids.map(id => (
                                                            <Chip
                                                                key={id}
                                                                label={getUnitListName(id)}
                                                                size="small"
                                                                onClick={() => handleListClick(id, 'unit')}
                                                                style={{ cursor: 'pointer' }}
                                                                color="secondary"
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {column.id === 'stay_dates' && (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                    <div style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                        {list.date_count} dates
                                                    </div>
                                                    {list.stay_date_list_id && (
                                                        <Chip
                                                            label={getDateListName(list.stay_date_list_id)}
                                                            size="small"
                                                            onClick={() => handleListClick(list.stay_date_list_id, 'date')}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {column.id === 'blackout_dates' && (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                                    {list.blackout_date_list_id && (
                                                        <>
                                                            <div style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                {list.excluded_date_count} dates
                                                            </div>
                                                            <Chip
                                                                label={getDateListName(list.blackout_date_list_id)}
                                                                size="small"
                                                                onClick={() => handleListClick(list.blackout_date_list_id, 'date')}
                                                                style={{ cursor: 'pointer' }}
                                                                color="secondary"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            {column.id === 'unit_nights' && (
                                                <Tooltip title={`${formatNumberWithCommas(list.unit_night_count)} nights total${
                                                    list.excluded_unit_night_count > 0 
                                                        ? `, ${formatNumberWithCommas(list.excluded_unit_night_count)} excluded`
                                                        : ''
                                                }`}>
                                                    <span>
                                                        {formatLargeNumber(list.unit_night_count)}
                                                        {list.excluded_unit_night_count > 0 &&
                                                            ` (${formatLargeNumber(list.excluded_unit_night_count)} excl.)`
                                                        }
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {column.id === 'creator' && (
                                                <Tooltip title={formatCreationDate(list.created_at)}>
                                                    <span>{formatCreatorName(list.creator)}</span>
                                                </Tooltip>
                                            )}
                                            {column.id === 'created_at' && (
                                                <Tooltip title={formatCreationDate(list.created_at)}>
                                                    <span>{formatCreationDate(list.created_at)}</span>
                                                </Tooltip>
                                            )}
                                            {column.id === 'updater' && (
                                                <Tooltip title={formatCreationDate(list.updated_at)}>
                                                    <span>{formatCreatorName(list.updater)}</span>
                                                </Tooltip>
                                            )}
                                            {column.id === 'updated_at' && (
                                                <Tooltip title={formatCreationDate(list.updated_at)}>
                                                    <span>{formatCreationDate(list.updated_at)}</span>
                                                </Tooltip>
                                            )}
                                            {column.id === 'exclude_events' && (
                                                <Tooltip title={
                                                    !list.exclude_events 
                                                        ? "No events are excluded" 
                                                        : list.excluded_event_ids?.length > 0
                                                            ? `${list.excluded_event_ids.length} specific events excluded`
                                                            : "ALL events are excluded"
                                                }>
                                                    <Chip
                                                        label={list.exclude_events ? "Yes" : "No"}
                                                        size="small"
                                                        color={list.exclude_events ? "primary" : "default"}
                                                        style={{ 
                                                            minWidth: '60px',
                                                            backgroundColor: list.exclude_events ? undefined : '#f5f5f5'
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {column.id === 'excluded_event_ids' && (
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                    {!list.exclude_events ? (
                                                        <Chip
                                                            label="-"
                                                            size="small"
                                                            style={{ backgroundColor: '#f5f5f5' }}
                                                        />
                                                    ) : list.excluded_event_ids?.length > 0 ? (
                                                        list.excluded_event_ids.map(id => {
                                                            const event = getEventDetails(id, holidays);
                                                            return (
                                                                <Tooltip 
                                                                    key={id} 
                                                                    title={event ? `${event.holidayName}: ${event.title}` : `Event ${id}`}
                                                                >
                                                                    <Chip
                                                                        label={event ? event.title : id}
                                                                        size="small"
                                                                        color="secondary"
                                                                        onClick={() => onTabChange('holidays', event.title)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </Tooltip>
                                                            );
                                                        })
                                                    ) : (
                                                        <Chip
                                                            label="ALL"
                                                            size="small"
                                                            color="secondary"
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {column.id === 'included_rebl_rules' && (
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                    {list.included_rebl_rule_ids?.map(id => (
                                                        <Tooltip 
                                                            key={`rule-${id}`}
                                                            title={`Rule #${id} by ${reblRules?.find(r => r.id === id)?.creator_email || 'unknown'}`}
                                                        >
                                                            <Chip
                                                                label={`${id}: ${getReblRuleDetails(id, reblRules)?.creator_email || 'unknown'}`}
                                                                size="small"
                                                                color="primary"
                                                                onClick={() => onTabChange('rebl', `#${id}`)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Tooltip>
                                                    ))}
                                                </div>
                                            )}
                                            {column.id === 'excluded_rebl_rules' && (
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                    {list.excluded_rebl_rule_ids?.map(id => (
                                                        <Tooltip 
                                                            key={`rule-${id}`}
                                                            title={`Rule #${id} by ${reblRules?.find(r => r.id === id)?.creator_email || 'unknown'}`}
                                                        >
                                                            <Chip
                                                                label={`${id}: ${getReblRuleDetails(id, reblRules)?.creator_email || 'unknown'}`}
                                                                size="small"
                                                                color="secondary"
                                                                onClick={() => onTabChange('rebl', `#${id}`)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Tooltip>
                                                    ))}
                                                </div>
                                            )}
                                            {column.id === 'include_events' && (
                                                <Tooltip title={
                                                    !list.include_events 
                                                        ? "No events are included" 
                                                        : list.included_event_ids?.length > 0
                                                            ? `${list.included_event_ids.length} specific events included`
                                                            : "ALL events are included"
                                                }>
                                                    <Chip
                                                        label={list.include_events ? "Yes" : "No"}
                                                        size="small"
                                                        color={list.include_events ? "primary" : "default"}
                                                        style={{ 
                                                            minWidth: '60px',
                                                            backgroundColor: list.include_events ? undefined : '#f5f5f5'
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {column.id === 'included_event_ids' && (
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                    {!list.include_events ? (
                                                        <Chip
                                                            label="-"
                                                            size="small"
                                                            style={{ backgroundColor: '#f5f5f5' }}
                                                        />
                                                    ) : list.included_event_ids?.length > 0 ? (
                                                        list.included_event_ids.map(id => {
                                                            const event = getEventDetails(id, holidays);
                                                            return (
                                                                <Tooltip 
                                                                    key={id} 
                                                                    title={event ? `${event.holidayName}: ${event.title}` : `Event ${id}`}
                                                                >
                                                                    <Chip
                                                                        label={event ? event.title : id}
                                                                        size="small"
                                                                        color="primary"
                                                                        onClick={() => onTabChange('holidays', event?.title || `Event ${id}`)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </Tooltip>
                                                            );
                                                        })
                                                    ) : (
                                                        <Chip
                                                            label="ALL"
                                                            size="small"
                                                            color="primary"
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {column.id === 'actions' && (
                                                <>
                                                    <Tooltip title="Edit">
                                                        <IconButton size="small" onClick={() => handleEdit(list)}>
                                                            <Icon.Edit fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Copy">
                                                        <IconButton size="small" onClick={() => handleCopy(list)}>
                                                            <Icon.FileCopy fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={sortedAndFilteredLists.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(_, newPage) => setPage(newPage)}
                onChangeRowsPerPage={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />
            <Modal
                open={openModal}
                onClose={handleModalClose}
            >
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '2rem',
                    borderRadius: '4px',
                    minWidth: '600px',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{ 
                        position: 'relative',
                        overflowY: 'auto',
                        paddingRight: '8px'
                    }}>
                        <UnitNightListForm
                            unitNightList={isCopying ? undefined : selectedUnitNightList}
                            initialValues={isCopying ? selectedUnitNightList : undefined}
                            unitLists={unitLists}
                            isLoadingUnitLists={isLoadingUnitLists}
                            refetchUnitLists={refetchUnitLists}
                            dateLists={dateLists}
                            isLoadingDateLists={isLoadingDateLists}
                            refetchDateLists={refetchDateLists}
                            onCancel={handleModalClose}
                            holidays={holidays}
                            isLoadingHolidays={isLoadingHolidays}
                            reblRules={reblRules}
                            isLoadingReblRules={isLoadingReblRules}
                        />
                    </div>
                </div>
            </Modal>
        </Paper>
    );
}; 