import React, { useState, useRef } from 'react';
import { Button, Menu, MenuItem, TextField, Chip, FormControl } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import * as Icon from '@material-ui/icons';
import { SelectOption } from '../../types';
import { UiUtils } from '../../utils';

interface GeographySelectorProps {
    label: string;
    options: string[];
    value: string;
    onChange: (value: string) => void;
}

export const GeographySelector: React.FC<GeographySelectorProps> = ({
    label,
    options,
    value,
    onChange
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef<HTMLInputElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setSearchValue('');
        setTimeout(() => {
            searchRef.current?.focus();
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchValue('');
    };

    const getFilteredOptions = (): SelectOption[] => {
        return options
            .map(s => UiUtils.getSelectOption(s))
            .filter(option => {
                const selectedValues = UiUtils.getValue(value)
                    .map(v => v.value.toString().toUpperCase());
                return !selectedValues.includes(option.value.toString().toUpperCase());
            })
            .filter(option => 
                option.display.toLowerCase().includes(searchValue.toLowerCase())
            );
    };

    const filteredOptions = getFilteredOptions();

    const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const option = filteredOptions[index];
        return (
            <MenuItem
                key={option.value}
                style={style}
                onClick={() => {
                    const currentValues = UiUtils.getValue(value);
                    onChange([...currentValues, option].map(v => v.value).join(","));
                }}
            >
                {option.display}
            </MenuItem>
        );
    };

    return (
        <div className="geography-selector">
            <FormControl variant="outlined">
                <Button
                    onClick={handleClick}
                    variant="outlined"
                    style={{
                        justifyContent: 'space-between',
                        width: '450px',
                        height: '40px',
                        textAlign: 'left'
                    }}
                >
                    {UiUtils.getValue(value).length > 0 
                        ? `${UiUtils.getValue(value).length} selected`
                        : `Select ${label}`}
                    <Icon.KeyboardArrowDown style={{ width: 16, height: 16, marginLeft: 'auto' }} />
                </Button>
            </FormControl>
            <div className="selected-items">
                {UiUtils.getValue(value).map((option: SelectOption) => (
                    <Chip
                        key={option.value}
                        label={option.display}
                        onDelete={() => {
                            const newValues = UiUtils.getValue(value)
                                .filter((o: SelectOption) => o.value !== option.value);
                            onChange(newValues.map(v => v.value).join(","));
                        }}
                    />
                ))}
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 400,
                        width: '400px',
                    },
                }}
            >
                <div className="menu-search" onClick={(e) => e.stopPropagation()}>
                    <TextField
                        inputRef={searchRef}
                        placeholder="Type to filter..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </div>
                {filteredOptions.length > 0 ? (
                    <List
                        height={300}
                        itemCount={filteredOptions.length}
                        itemSize={48}
                        width="100%"
                    >
                        {Row}
                    </List>
                ) : (
                    <MenuItem disabled>No options available</MenuItem>
                )}
            </Menu>
        </div>
    );
}; 