import { RatePlan } from '../types';
import { apiPromotions } from './api';
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import { AppState } from './store';

const ratePlansAdapter = createEntityAdapter<RatePlan>({
  selectId: (ratePlan) => ratePlan.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export interface RatePlanRequest
  extends Pick<
    RatePlan,
    | 'name'
    | 'description'
    | 'channel'
    | 'non_refundable'
    | 'active_date_start'
    | 'active_date_end'
    | 'unit_night_list_id'
    | 'priority'
  > {}

export interface UpdateRatePlanRequest extends RatePlanRequest {
  id: number;
}

const initialState: EntityState<RatePlan> = ratePlansAdapter.getInitialState();

export const ratePlansApi = apiPromotions.injectEndpoints({
  endpoints: (builder) => ({
    getAllRatePlans: builder.query<RatePlan[], void>({
      query: () => 'rate_plans/',
    }),
    addRatePlan: builder.mutation<RatePlan, Partial<RatePlan>>({
      query: (body) => ({
        url: `rate_plans/`,
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    updateRatePlan: builder.mutation<
      RatePlan,
      { id: number; data: Partial<RatePlan> }
    >({
      query: ({ id, data }) => ({
        url: `rate_plans/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
    }),
  }),
  overrideExisting: false,
});

export const ratePlansSlice = createSlice({
  name: 'ratePlans',
  initialState,
  reducers: {
    merge: (state, action: PayloadAction<RatePlan[]>) => {
      ratePlansAdapter.upsertMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ratePlansApi.endpoints.getAllRatePlans.matchFulfilled,
      (state, { payload }) => {
        ratePlansAdapter.upsertMany(state, payload);
      }
    );
  },
});

export const {
  useGetAllRatePlansQuery,
  useAddRatePlanMutation,
  useUpdateRatePlanMutation,
} = ratePlansApi;

export const ratePlansSelector = (state: AppState) => state.ratePlans;

export default ratePlansSlice.reducer;
