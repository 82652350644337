import React, {useEffect, useState} from 'react';
import {BulkSidePanel, Loading} from '../index';
import './Tables.scss';
import {
    BulkUploadOptions,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    Message,
    SelectOption,
    UnitTetherAnchor,
    UnitTetherChild,
} from "../../types";
import {ButtonGroup, Icon, Input, Select, withTooltip} from '@vacasa/react-components-lib';
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {Checkbox} from '@material-ui/core';
import {useAddUnitTetherMutation, useUpdateUnitTetherMutation} from "../../store";
import {UiUtils} from "../../utils";

interface ChannelAnchorIDs {
    Vacasa?: string[];
    VRBO?: string[];
    AirBNB?: string[];
}


interface UnitTethersModalProps {
    selectedUnitTether: UnitTetherAnchor;
    formType: DemandInfluenceFormType;
    closeModal: (refresh?: boolean) => void;
    allTethers: UnitTetherAnchor[];
    isFetchingTethers: boolean;
}

export const UnitTetherModal: React.FC<UnitTethersModalProps> = (props) => {
    const {
        selectedUnitTether,
        formType,
        closeModal,
        allTethers,
        isFetchingTethers
    } = props

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");
    const valueTypeOptions: SelectOption[] = ["Dollar", "Percent"].map(v => UiUtils.getSelectOption(v));
    const [isShowingSave, setIsShowingSave] = useState(false);

    // Form fields
    const [active, setActive] = useState<boolean>(true);
    const [unitID, setUnitID] = useState<number>();
    const [title, setTitle] = useState<string>("");
    const [unitType, setUnitType] = useState<string>("");
    const unitTypeOptions = [
        "Super Unit Parent",
        "Lockoff Unit",
        "MUL Anchor Unit",
        "Prime Custom Unit",
        "Prime Cohort Unit",
        "Prime OPS Market Unit",
        "Prime OPS Market Waterfront Unit",
        "Prime OPS Market Ski/in out Unit",
        "Prime OPS Market Private Pool Unit",
        "Prime Super Region Unit",
        "Prime GW Unit",
        "Prime HVO Unit",
    ].map(t => UiUtils.getSelectOption(t));
    const [vacasaTetherID, setVacasaTetherID] = useState<number>(); // Ensure Vacasa units are integers
    const [channelAnchorUnitID, setChannelAnchorUnitID] = useState<string>("");
    const [anchorChannel, setAnchorChannel] = useState<string>("Vacasa")
    const [tetheredUnits, setTetheredUnits] = useState<UnitTetherChild[]>([]);
    const unitBrands = ["Vacasa", "VRBO", "AirBNB"].map(k => UiUtils.getSelectOption(k));

    const [anchorIDsByChannel, setAnchorIDsByChannel] = useState<ChannelAnchorIDs>(null);
    const [tetherMap, setTetherMap] = useState<{number: string}>(null);

    const [addUnitTetherAnchor] = useAddUnitTetherMutation();
    const [updateUnitTetherAnchor] = useUpdateUnitTetherMutation();

    const addTether = () => {
        let updatedTethers = [...tetheredUnits]
        updatedTethers.push({
            unit_id: null,
            tether_value_type: "Dollar",
            tether_value: 50,
            note: "",
        })
        setTetheredUnits(updatedTethers)
    }

    const handleUpload = (message: Message, data: string[]) => {
        setIsShowingSave(false);

        let newTethers: UnitTetherChild[] = [...tetheredUnits.filter(t => !!t.unit_id)];
        const currentCount = newTethers.length;

        for (let line of data) {
            if (
                line.indexOf("Unit ID is required") === 0 ||
                line.indexOf("unit_id,") === 0 ||
                !line
            ) continue;

            line = line.trim();
            const values = line.split(",")
            if (values.length === 4) {
                newTethers.push({
                    unit_id: parseInt(values[0]),
                    tether_value_type: values[1].toUpperCase() === "PERCENT" ?
                        "Percent" :
                        values[1].toUpperCase() === "DOLLAR" ?
                            "Dollar" :
                            "",
                    tether_value: parseInt(values[2]),
                    note: values[3],
                })
            }
        }

        if (newTethers.length === currentCount) {
            alert("Could not parse uploaded data");
            return;
        }

        setTetheredUnits(newTethers)
    }

    const removeTether = (idx: number) => {
        let updatedTethers = [...tetheredUnits]
        updatedTethers.splice(idx, 1)
        setTetheredUnits(updatedTethers)
    }

    const updateTetheredUnit = (tether: UnitTetherChild, column: string, idx: number, value: number | string) => {
        let updatedTethers = [...tetheredUnits]
        let updatedTether = {...tether};
        updatedTether[column] = value;
        updatedTethers[idx] = updatedTether
        setTetheredUnits(updatedTethers)
    }

    const handleSave = async () => {
        setIsSaving(true);

        let updatedTether: Partial<UnitTetherAnchor> = {
            active: active,
            unit_id: anchorChannel === "Vacasa" ? unitID.toString() : channelAnchorUnitID,
            title: title,
            unit_type: unitType,
            channel: anchorChannel,
            tethers: tetheredUnits,
        }

        if (formType === DemandInfluenceFormOptions.EDIT) {
            await updateUnitTetherAnchor({
                id: selectedUnitTether.id,
                data: updatedTether
            }).then((response) => {
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                setIsSaving(false);
                closeModal(true);
            })
        }
        else { // ADD and COPY
            await addUnitTetherAnchor(updatedTether).then((response) => {
                setIsSaving(false);
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                else {
                    closeModal(true);
                }
            })
        }
    }

    const getValueTooltip = (row: UnitTetherChild) => {
        const exampleRate = 200;
        const pct = row.tether_value_type === "Percent";
        let offset: number = !!row.tether_value ? row.tether_value : 0;
        if (pct) offset = offset / 100;
        const offsetString = (
            (offset >= 0 ? "+" : "-") +
            (!pct ? "$" : "") +
            (Math.abs(row.tether_value).toString()) +
            (pct ? "%" : "")
        )

        let newRate: number;
        if (pct) {
            newRate = exampleRate + exampleRate * offset
        }
        else {
            newRate = exampleRate + offset
        }

        return `An offset of ${offsetString} would turn a $${exampleRate.toString()} rate into $${newRate}`;

    }

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: disableSave || isSaving,
        customClass: 'button-group'
    }

    useEffect(() => {
        // All Anchor IDs by Channel
        if (isFetchingTethers) return;

        if (anchorIDsByChannel === null) {
            let newAnchorIDsByChannel: ChannelAnchorIDs = {};

            unitBrands.forEach(channel => {
                newAnchorIDsByChannel[channel.value] = []
            });
            allTethers.forEach(t => {
                newAnchorIDsByChannel[t.channel].push(t.unit_id)
            });

            setAnchorIDsByChannel(newAnchorIDsByChannel);
        }

        let newTetherMap: any = {};
        allTethers.forEach(ut => {
            ut.tethers.forEach(t => {
                newTetherMap[t.unit_id] = ut.unit_id;
            });
        });
        setTetherMap(newTetherMap);

        if (!!selectedUnitTether) {
            setActive(selectedUnitTether.active);
            if (selectedUnitTether.channel === "Vacasa") {
                setUnitID(parseInt(selectedUnitTether.unit_id));
            }
            else {
                setChannelAnchorUnitID(selectedUnitTether.unit_id)
            }
            setTitle(selectedUnitTether.title);
            setUnitType(selectedUnitTether.unit_type);
            setAnchorChannel(selectedUnitTether.channel);
            let tethers = [...selectedUnitTether.tethers];
            setTetheredUnits(tethers.sort((a, b) => a.unit_id - b.unit_id));
        }
        else if (!tetheredUnits.length){
            addTether()
        }
        setIsLoading(false);
    }, [allTethers, isFetchingTethers]);

    useEffect(() => {
        if (isFetchingTethers) return;

        let errorMessages = [];

        if (!title) {
            errorMessages.push("Title is required");
        }
        if (!unitType) {
            errorMessages.push("Unit type is required");
        }

        if (anchorChannel === "Vacasa") {
            if (!unitID || unitID <= 0) {
                errorMessages.push("Anchor ID is required and must be positive");
            }
            else {
                if (formType === DemandInfluenceFormOptions.EDIT) {
                    if (unitID.toString() !== selectedUnitTether.unit_id && anchorIDsByChannel[anchorChannel].indexOf(unitID.toString()) >= 0) {
                        errorMessages.push("Anchor ID already in use");
                    }
                }
                else { // ADDING
                    if (anchorIDsByChannel[anchorChannel].indexOf(unitID.toString()) >= 0) {
                        errorMessages.push("Anchor ID already in use");
                    }
                }
                if (tetherMap.hasOwnProperty(unitID)) {
                    errorMessages.push(`Anchor unit ${unitID} already tethered to unit ${tetherMap[unitID]}`);
                }
            }
        }
        else {
            if (!channelAnchorUnitID) {
                errorMessages.push("Anchor ID is required");
            }
            else if (formType === DemandInfluenceFormOptions.EDIT) {
                if (channelAnchorUnitID !== selectedUnitTether.unit_id && anchorIDsByChannel[anchorChannel].indexOf(channelAnchorUnitID)) {
                    errorMessages.push("Anchor ID already in use");
                }
            }
            else { // ADDING
                if (anchorIDsByChannel[anchorChannel].indexOf(channelAnchorUnitID)) {
                    errorMessages.push("Anchor ID already in use");
                }
            }
        }

        if (tetheredUnits.length < 1) {
            errorMessages.push("Please add one or more tethered units");
        }
        else if (tetheredUnits.filter(ut => !ut.unit_id || Number.isNaN(ut.tether_value) || !ut.tether_value_type).length > 0) {
            errorMessages.push("Please ensure all tethered units have valid inputs");
        }
        else {
            tetheredUnits.forEach(t => {
                if (anchorChannel === "Vacasa") {
                    if (t.unit_id === unitID) {
                        errorMessages.push(`Tethered unit ID cannot match anchor ID`);
                    }
                    if (unitType.toUpperCase() !== "SUPER UNIT PARENT" && tetherMap.hasOwnProperty(t.unit_id) && tetherMap[t.unit_id] !== unitID.toString()) {
                        errorMessages.push(`Tethered unit ${t.unit_id.toString()} is already tethered to unit ${tetherMap[t.unit_id]}`);
                    }
                }
                else {
                    if (t.unit_id.toString() === channelAnchorUnitID) {
                        errorMessages.push(`Tethered unit ID cannot match anchor ID`);
                    }
                    if (tetherMap.hasOwnProperty(t.unit_id) && tetherMap[t.unit_id] !== channelAnchorUnitID) {
                        errorMessages.push(`Tethered unit ${t.unit_id.toString()} is already tethered to unit ${tetherMap[t.unit_id]}`);
                    }
                }

                if (anchorIDsByChannel[anchorChannel].indexOf(t.unit_id.toString()) >= 0) {
                    errorMessages.push(`Tethered unit ${t.unit_id.toString()} is an anchor`);
                }

                if (t.tether_value_type === "Percent" && t.tether_value <= -90) {
                    errorMessages.push("Cannot discount more than -90%");
                }
            });
        }
        if (new Set(tetheredUnits.map(t => t.unit_id)).size !== tetheredUnits.length) {
            errorMessages.push("Tethered unit IDs must be unique");
        }

        if (errorMessages.length > 0) {
            setValidationErrorMsg(`${errorMessages.length} error(s); ${errorMessages[0]} `);
        }
        else {setValidationErrorMsg("")}

        setDisableSave(errorMessages.length > 0);

    }, [unitID, title, unitType, vacasaTetherID, tetheredUnits, isFetchingTethers]);

    return (
        <div>
            <div className="ut-form-header">
                <h4>Tethered Units</h4>
            </div>
            <div className="ut-form-body table-body">
                {isLoading || isFetchingTethers
                    ? <Loading className="table-modal-loading"/>
                    : <div>
                        <div className="table-form-row">
                            <div className="table-form-group">
                                <label>Title:</label>
                                <Input
                                    customClass="title-input"
                                    type="text"
                                    value={title}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="table-form-group">
                                <p>Active:</p>
                                <Checkbox
                                    checked={active}
                                    onChange={() => setActive(!active)}
                                />
                            </div>
                        </div>
                        <div className="table-form-row">
                            <div className="table-form-group">
                                <label>Unit Type:</label>
                                <Select
                                    customClass="select-input"
                                    options={unitTypeOptions}
                                    value={unitType}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUnitType(e.target.value)}
                                />
                            </div>
                            <div className="table-form-group">
                                <label>{unitType.toUpperCase() === "SUPER UNIT PARENT"  ? "Super" : "Anchor"} Unit ID:</label>
                                {anchorChannel === "Vacasa" ?
                                    <Input
                                        customClass="default-input"
                                        type="number"
                                        min={1}
                                        value={unitID}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUnitID(e.target.valueAsNumber)}
                                    />
                                    :
                                    <Input
                                        customClass="default-input"
                                        type="text"
                                        value={channelAnchorUnitID}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChannelAnchorUnitID(e.target.value)}
                                    />
                                }
                            </div>
                        </div>
                        {/*<div className="table-form-row">*/}
                        {/*    <div className="table-form-group">*/}
                        {/*        <label>Channel:</label>*/}
                        {/*        <Select*/}
                        {/*            customClass="default-input"*/}
                        {/*            value={anchorChannel}*/}
                        {/*            options={unitBrands}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setAnchorChannel(e.target.value)*/}
                        {/*            }}*/}
                        {/*            disabled={true}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <hr/>
                        <div className="table-form-row">
                            {
                                withTooltip(
                                    "Add tethered unit",
                                    <div className="table-form-group" style={{cursor: "pointer"}}>
                                        <Icon.PlusCircle onClick={addTether} height={24} width={24}/>
                                    </div>,
                                    { placement: 'top' }
                                )
                            }
                            {
                                withTooltip(
                                    "Upload tethers",
                                    <div className="table-form-group" style={{cursor: "pointer"}}>
                                        <Icon.Upload onClick={() => setIsShowingSave(true)} height={24} width={24}/>
                                    </div>,
                                    { placement: 'top' }
                                )
                            }
                        </div>
                        <div>
                            <table className={"tether-table"}>
                                <thead>
                                <tr className={"tether-table-header"}>
                                    <th className={"unit-id-col"}>{unitType.toUpperCase() === "SUPER UNIT PARENT" && "Sub"} Unit ID</th>
                                    <th className={"value-type-col"}>Value Type</th>
                                    <th className={"value-col"}>Offset</th>
                                    <th className={"note-col"}>Notes</th>
                                    <th className={"action-col"}>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tetheredUnits.map((t, idx) => (
                                    <tr className={"tether-table-body"}>
                                        <td>
                                            <Input
                                                customClass="unit-input"
                                                type="number"
                                                min={1}
                                                value={tetheredUnits[idx].unit_id}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTetheredUnit(t, "unit_id", idx, e.target.valueAsNumber)}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                customClass="default-input"
                                                value={tetheredUnits[idx].tether_value_type}
                                                options={valueTypeOptions}
                                                onChange={(e) => {
                                                    updateTetheredUnit(t, "tether_value_type", idx, e.target.value)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                            {withTooltip(
                                                getValueTooltip(t),
                                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                    <Icon.AlertCircle height={14} width={14}/>
                                                    <Input
                                                        customClass="value-input"
                                                        type="number"
                                                        value={tetheredUnits[idx].tether_value}
                                                        onChange={(e) => {
                                                            updateTetheredUnit(t, "tether_value", idx, e.target.valueAsNumber)
                                                        }}
                                                    />
                                                </div>,
                                                { placement: "top" }
                                            )}
                                            </div>
                                        </td>
                                        <td>
                                            <Input
                                                customClass="note-input"
                                                type="text"
                                                value={tetheredUnits[idx].note}
                                                onChange={(e) => {
                                                    updateTetheredUnit(t, "note", idx, e.target.value)
                                                }}
                                                placeholder="Enter information about this unit"
                                            />
                                        </td>
                                        <td style={{width: "160px"}}>{
                                            withTooltip(
                                                "Remove row",
                                                <div className="table-form-group" style={{cursor: "pointer"}}>
                                                    <Icon.Trash onClick={() => removeTether(idx)} height={24} width={24}/>
                                                </div>,
                                                { placement: 'bottom' }
                                            )
                                        }</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {isShowingSave && (
                                <BulkSidePanel
                                    onClose={() => setIsShowingSave(false)}
                                    uploadType={BulkUploadOptions.TETHERS}
                                    onSave={handleUpload}
                                />
                            )}
                        </div>
                    </div>
                }
            </div>
            <div className="ut-form-footer" style={{height: "30px"}}>
                <span>
                    {validationErrorMsg}
                </span>
                <div className="form-footer-buttons">
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>
            </div>
        </div>
    );
};
