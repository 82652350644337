import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  CircularProgress,
  TablePagination,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextareaAutosize,
  Input,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  ListSubheader,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
  useGetUnitListMutation,
  useAddUnitListMutation,
  useUpdateUnitListMutation,
  useRefreshUnitListMutation,
  useLinkUnitsToParentMutation,
  // useGetAllReblRulesQuery,
} from '../../store';
import {Cohort, CurrentUser, LocationOptions, UnitList} from "../../types";
import { format } from 'date-fns';
import { FixedSizeList } from 'react-window';
import { UiUtils } from "../../utils";


type SortDirection = 'asc' | 'desc';

interface UnitListsProps {
  unitLists: UnitList[],
  isLoading: boolean,
  refetch: () => void,
  locationOptions: LocationOptions;
  isFetchingLocationOptions: boolean;
  cohortData: Cohort[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  currentUser: CurrentUser;
}

const VirtualizedMenuItems = React.memo(({ 
  items, 
  onSelect, 
  source, 
  unitLists,
  cohortData,
  searchTerm = ''
}: { 
  items: string[], 
  onSelect: (value: string) => void,
  source?: string,
  unitLists?: UnitList[],
  cohortData?: Cohort[],
  searchTerm?: string
}) => {
  const itemHeight = 48;
  
  const getFilteredItems = () => {
    const search = searchTerm.toLowerCase();
    if (!search) return items;
    
    if (source === 'Intersection') {
      return items.filter(value => {
        const list = unitLists?.find(ul => ul.id.toString() === value);
        return list?.name.toLowerCase().includes(search);
      });
    }
    
    if (source === 'strategic_cohorts') {
      return items.filter(value => {
        const cohort = cohortData?.find(c => c.id.toString() === value);
        return cohort?.name.toLowerCase().includes(search);
      });
    }
    
    // For geography options
    return items.filter(item => item.toLowerCase().includes(search));
  };

  const filteredItems = getFilteredItems();
  
  const Row = ({ index, style }: { index: number, style: React.CSSProperties }) => {
    const value = filteredItems[index];
    let label = value;
    
    // Get display label based on source type
    if (source === 'Intersection' && unitLists) {
      const list = unitLists.find(ul => ul.id.toString() === value);
      if (list) {
        label = list.name;
      }
    } else if (source === 'strategic_cohorts' && cohortData) {
      const cohort = cohortData.find(c => c.id.toString() === value);
      if (cohort) {
        label = cohort.name;
      }
    } else {
      // For geography options, capitalize first letter
      label = value.charAt(0).toUpperCase() + value.slice(1);
    }

    return (
      <MenuItem 
        key={value} 
        value={value}
        onClick={() => onSelect(value)}
        style={style}
      >
        {label}
      </MenuItem>
    );
  };

  return (
    <FixedSizeList
      height={Math.min(filteredItems.length * itemHeight, 300)}
      width="100%"
      itemCount={filteredItems.length}
      itemSize={itemHeight}
    >
      {Row}
    </FixedSizeList>
  );
});

const getSourceOptions = (
    source: string | null, 
    listId: number, 
    editingStates: { [key: number]: Omit<UnitList, 'id'> },
    unitLists?: UnitList[],
    cohortData?: Cohort[],
    locationOptions?: LocationOptions
) => {
    if (!source) return [];
    
    switch (source) {
      case 'Intersection':
        return unitLists
          ?.filter(list => !editingStates[listId]?.source_values?.includes(list.id.toString()))
          .map(list => list.id.toString()) || [];
      case 'strategic_cohorts':
        return cohortData
          ?.filter(cohort => !editingStates[listId]?.source_values?.includes(cohort.id.toString()))
          .map(cohort => cohort.id.toString()) || [];
      default:
        return (locationOptions?.[source] || [])
          .filter(value => !editingStates[listId]?.source_values?.includes(value));
    }
};

export const UnitListsTable: React.FC<UnitListsProps> = ({
  unitLists,
  isLoading,
  refetch,
  searchQuery,
  setSearchQuery,
  locationOptions, 
  isFetchingLocationOptions,
  cohortData ,
  currentUser,
}) => {
  // const {refetch: refetchRebl, data: reblRules, isFetching: isFetchingReblRules} = useGetAllReblRulesQuery();
  const [sortBy, setSortBy] = useState<keyof UnitList>('id');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingRows, setEditingRows] = useState<{ [key: number]: boolean }>({});
  const [editingStates, setEditingStates] = useState<{ [key: number]: Omit<UnitList, 'id'> }>({});
  const [savingRows, setSavingRows] = useState<{ [key: number]: boolean }>({});
  const [refreshUnitList] = useRefreshUnitListMutation();
  const [refreshingRows, setRefreshingRows] = useState<{ [key: number]: boolean }>({});
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedUnitList, setSelectedUnitList] = useState<UnitList | null>(null);
  const [unitIdsText, setUnitIdsText] = useState('');
  const [uploadingUnits, setUploadingUnits] = useState<{ [key: number]: boolean }>({});
  const [linkUnitsToParent] = useLinkUnitsToParentMutation();
  const [downloadingRows, setDownloadingRows] = useState<{ [key: number]: boolean }>({});
  const [sourceValueSearch, setSourceValueSearch] = useState<{ [key: number]: string }>({});
  const [nameErrors, setNameErrors] = useState<{ [key: number]: string }>({});

  const [createUnitList] = useAddUnitListMutation();
  const [updateUnitList] = useUpdateUnitListMutation();
  const [isRefetching, setIsRefetching] = useState(true);

  useEffect(() => {
    setIsRefetching(isFetchingLocationOptions || isLoading)
  }, [isFetchingLocationOptions, isLoading]);

  const [getUnitList] = useGetUnitListMutation();

  const handleSort = (column: keyof UnitList) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddUnitList = async () => {
    const newUnitList: Omit<UnitList, 'id'> = {
      name: 'New Unit List',
      admin_only: false,
      description: 'add a description',
      source: 'Manual',
      source_values: null,
    };

    try {
      setIsRefetching(true);
      await createUnitList(newUnitList).unwrap();
      await refetch();
      
      // Reset to first page to show the new row
      setPage(0);
      
      // Change sort to show new unit list at the top
      setSortBy('id');
      setSortDirection('desc');
      setSearchQuery('');
    } catch (error) {
      console.error('Failed to create unit list:', error);
    } finally {
      setTimeout(() => {
        setIsRefetching(false);
      }, 1000)
    }
  };

  const handleEdit = (unitList: UnitList) => {
    if (unitList.admin_only && !currentUser?.admin) {
      // Could add a snackbar or other notification here
      console.warn('Only admins can edit admin-only lists');
      return;
    }
    
    setEditingRows(prev => ({
      ...prev,
      [unitList.id]: !prev[unitList.id]
    }));

    setEditingStates(prev => ({
      ...prev,
      [unitList.id]: {
        name: unitList.name,
        admin_only: unitList.admin_only,
        description: unitList.description,
        source: unitList.source || null,
        source_values: unitList.source_values || [],
      }
    }));
  };

  const handleSave = async (unitList: UnitList) => {
    try {
      setSavingRows(prev => ({
        ...prev,
        [unitList.id]: true
      }));

      const editingState = editingStates[unitList.id];
      const updatedUnitList = {
        ...unitList,
        ...editingState,
      };
      await updateUnitList({id: unitList.id, data: updatedUnitList});

      setEditingRows(prev => ({
        ...prev,
        [unitList.id]: false
      }));
      setEditingStates(prev => {
        const newState = { ...prev };
        delete newState[unitList.id];
        return newState;
      });

      await refetch();
    } catch (error) {
      console.error('Failed to update unit list:', error);
    } finally {
      setSavingRows(prev => ({
        ...prev,
        [unitList.id]: false
      }));
    }
  };

  const handleCancel = (id: number) => {
    setEditingRows(prev => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
    
    setEditingStates(prev => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
  };

  const handleRefreshRow = async (unitList: UnitList) => {
    try {
      setRefreshingRows(prev => ({
        ...prev,
        [unitList.id]: true
      }));

      await refreshUnitList({id: unitList.id, data: unitList});
      await refetch();
    } catch (error) {
      console.error('Failed to refresh unit list:', error);
    } finally {
      setRefreshingRows(prev => ({
        ...prev,
        [unitList.id]: false
      }));
    }
  };

  const handleUploadClick = (unitList: UnitList) => {
    setSelectedUnitList(unitList);
    setUploadDialogOpen(true);
    setUnitIdsText('');
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        setUnitIdsText(text);
      };
      reader.readAsText(file);
    }
  };

  const handleUploadSubmit = async () => {
    if (!selectedUnitList) return;

    try {
      setUploadingUnits(prev => ({
        ...prev,
        [selectedUnitList.id]: true
      }));

      // Parse unit IDs from text (assuming comma or newline separated)
      const unitIds = unitIdsText
        .split(/[,\n]/)
        .map(id => id.trim())
        .filter(id => id !== '')
        .map(id => parseInt(id))
        .filter(id => !isNaN(id));

      await linkUnitsToParent({
        id: selectedUnitList.id,
        data: unitIds
      });

      await refetch();
      setUploadDialogOpen(false);
      setSelectedUnitList(null);
      setUnitIdsText('');
    } catch (error) {
      console.error('Failed to upload units:', error);
    } finally {
      setUploadingUnits(prev => ({
        ...prev,
        [selectedUnitList.id]: false
      }));
    }
  };

  const handleDownload = async (unitList: UnitList) => {
    try {
      setDownloadingRows(prev => ({
        ...prev,
        [unitList.id]: true
      }));

      // Fetch the unit list data
      const response = await getUnitList(unitList.id as number).unwrap();
      // Create CSV content
      const csvContent = `Unit IDs\n${response.join('\n')}`;
      
      // Create blob and download link
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', `unit-list-${unitList.id}.csv`);
      link.style.visibility = 'hidden';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    } catch (error) {
      console.error('Failed to download unit list:', error);
    } finally {
      setDownloadingRows(prev => ({
        ...prev,
        [unitList.id]: false
      }));
    }
  };

  const filteredAndSortedData = useMemo(() => {
    if (!unitLists) return [];

    let filtered = unitLists;
    
    // Apply search filter
    if (searchQuery) {
        filtered = filtered.filter(list => 
            list.name.toLowerCase() === searchQuery.toLowerCase() ||  // Exact match for chip clicks
            list.name.toLowerCase().includes(searchQuery.toLowerCase()) ||  // Partial match for typing
            list.description?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }

    // Apply sorting
    return [...filtered].sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (aValue === bValue) return 0;
        const comparison = aValue < bValue ? -1 : 1;
        return sortDirection === 'asc' ? comparison : -comparison;
    });
  }, [unitLists, searchQuery, sortBy, sortDirection]);

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredAndSortedData, page, rowsPerPage]);

  const formatDate = (updatedAt: string | null | undefined, createdAt: string | null | undefined) => {
    if (updatedAt) {
      try {
        return `Last updated: ${format(new Date(updatedAt), 'MMM d, yyyy HH:mm:ss')}`;
      } catch (e) {
        return 'Invalid update date';
      }
    } else if (createdAt) {
      try {
        return `Created: ${format(new Date(createdAt), 'MMM d, yyyy HH:mm:ss')}`;
      } catch (e) {
        return 'Invalid creation date';
      }
    }
    return 'No date available';
  };

  const getValueOptions = (source: string, currentListId: number): string[] => {
    if (!source) return [];
    
    source = source.toUpperCase();
    if (source === "INTERSECTION") {
      return unitLists 
        ? unitLists
            .filter(ul => ul.id !== currentListId) // Exclude current list
            .map(ul => ul.id.toString())
        : [];
    }
    return getGeographyValueOptions(source);
  };

  // Add helper function to get geography value options
  const getGeographyValueOptions = (geographyType: string): string[] => {
    if (!locationOptions || !geographyType) return [];
    return locationOptions[geographyType.toLowerCase()] || [];
  };

  // Add a helper function for formatting creator tooltip
  const formatCreatorTooltip = (createdAt: string | null | undefined) => {
    if (!createdAt) return 'Creation date unknown';
    try {
      return `Created on: ${format(new Date(createdAt), 'MMM d, yyyy HH:mm:ss')}`;
    } catch (e) {
      return 'Invalid creation date';
    }
  };

  // Add helper function to check name uniqueness
  const isNameUnique = (name: string, currentId: number) => {
    return !unitLists?.some(list => 
      list.id !== currentId && // Ignore current list
      list.name.toLowerCase() === name.toLowerCase()
    );
  };

  // Add effect for escape key handling
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        // Get the ID of the row being edited
        const editingId = Object.entries(editingRows)
          .find(([_, isEditing]) => isEditing)?.[0];
        
        if (editingId) {
          handleCancel(parseInt(editingId));
        }
      }
    };

    // Only add listener if we're editing a row
    if (Object.values(editingRows).some(isEditing => isEditing)) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editingRows]);

  // Add helper function to get the display name for source values
  const getSourceValueDisplay = (
    value: string, 
    source: string | null | undefined, 
    unitLists?: UnitList[]
  ) => {
    if (source === 'Intersection' && unitLists) {
        const list = unitLists.find(ul => ul.id.toString() === value);
        return list ? list.name : value;
    }
    
    if (source === 'strategic_cohorts' && cohortData) {
        const cohort = cohortData.find(c => c.id.toString() === value);
        return cohort ? cohort.name : value;
    }

    return value;
  };

  // Add effect to handle initial selection
  useEffect(() => {
    if (searchQuery) {
        const list = unitLists?.find(l => l.name.toLowerCase() === searchQuery.toLowerCase());
        if (list) {
            handleEdit(list);
        }
    }
  }, [searchQuery, unitLists]);

  // Add helper function to format creator name (similar to DateLists)
  const formatCreatorName = (email: string) => {
    return email ? email.split('@')[0] : '-';
  };

  const classes = UiUtils.useTableStyles();

  return (
    <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ 
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}>
        <TextField
          label="Search Unit Lists"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: '450px' }}
          InputProps={{
            startAdornment: <Icon.Search style={{ color: 'gray', marginRight: 8 }} />,
            endAdornment: searchQuery && (
              <IconButton
                size="small"
                onClick={() => setSearchQuery('')}
                style={{ padding: 4 }}
              >
                <Icon.Close fontSize="small" />
              </IconButton>
            )
          }}
        />
        <Tooltip title="Add Unit List">
          <IconButton
              onClick={handleAddUnitList}
              size="small"
              disabled={isRefetching}
          >
            <Icon.Add fontSize="small"/>
          </IconButton>
        </Tooltip>

        <Tooltip title={isRefetching ? "Loading..." : "Refresh data"}>
          <IconButton
              onClick={() => refetch()}
              disabled={isRefetching}
              size="small"
          >
            {isRefetching ? (
                <CircularProgress size={16} />
            ) : (
                <Icon.Replay fontSize="small"/>
            )}
          </IconButton>
        </Tooltip>
      </Box>

      <div style={{ position: 'relative' }}>
        {(isRefetching) && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
            <CircularProgress />
          </div>
        )}

        <TableContainer 
            className={classes.tableContainer}
            style={{ height: '600px', overflow: 'auto' }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'id'}
                    direction={sortBy === 'id' ? sortDirection : 'asc'}
                    onClick={() => handleSort('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'name'}
                    direction={sortBy === 'name' ? sortDirection : 'asc'}
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'admin_only'}
                    direction={sortBy === 'admin_only' ? sortDirection : 'asc'}
                    onClick={() => handleSort('admin_only')}
                  >
                    Admin
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'creator'}
                    direction={sortBy === 'creator' ? sortDirection : 'asc'}
                    onClick={() => handleSort('creator')}
                  >
                    Creator
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'source'}
                    direction={sortBy === 'source' ? sortDirection : 'asc'}
                    onClick={() => handleSort('source')}
                  >
                    Source
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  Unit Count
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((list) => (
                <TableRow 
                    key={list.id} 
                    id={`unit-list-${list.id}`}
                    style={list.name === searchQuery ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' } : undefined}
                >
                  <TableCell>{list.id}</TableCell>
                  {editingRows[list.id] ? (
                    <>
                      <TableCell colSpan={3}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <TextField
                            value={editingStates[list.id]?.name}
                            onChange={(e) => {
                              const newName = e.target.value;
                              setEditingStates(prev => ({
                                ...prev,
                                [list.id]: {
                                  ...prev[list.id],
                                  name: newName
                                }
                              }));
                              
                              // Validate name uniqueness
                              if (!newName.trim()) {
                                setNameErrors(prev => ({
                                  ...prev,
                                  [list.id]: 'Name is required'
                                }));
                              } else if (!isNameUnique(newName, list.id)) {
                                setNameErrors(prev => ({
                                  ...prev,
                                  [list.id]: 'Name must be unique'
                                }));
                              } else {
                                setNameErrors(prev => {
                                  const newErrors = { ...prev };
                                  delete newErrors[list.id];
                                  return newErrors;
                                });
                              }
                            }}
                            size="small"
                            variant="outlined"
                            fullWidth
                            label="Name"
                            error={!!nameErrors[list.id]}
                            helperText={nameErrors[list.id]}
                          />
                          <TextField
                            value={editingStates[list.id]?.description}
                            onChange={(e) => {
                              setEditingStates(prev => ({
                                ...prev,
                                [list.id]: {
                                  ...prev[list.id],
                                  description: e.target.value
                                }
                              }));
                            }}
                            size="small"
                            variant="outlined"
                            fullWidth
                            label="Description"
                          />
                          {currentUser?.admin && (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={editingStates[list.id]?.admin_only}
                                  onChange={(e) => {
                                    setEditingStates(prev => ({
                                      ...prev,
                                      [list.id]: {
                                        ...prev[list.id],
                                        admin_only: e.target.checked
                                      }
                                    }));
                                  }}
                                />
                              }
                              label="Admin Only"
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <FormControl variant="outlined" size="small" fullWidth>
                            <Select
                              value={editingStates[list.id]?.source || ''}
                              onChange={(e) => {
                                const newSource = e.target.value as string;
                                setEditingStates(prev => ({
                                  ...prev,
                                  [list.id]: {
                                    ...prev[list.id],
                                    source: newSource,
                                    source_values: ['All Units', 'New Units', 'No Bookings', 'strategic_cohorts'].includes(newSource) ? [] : null
                                  }
                                }));
                              }}
                              disabled={isRefetching}
                            >
                              <MenuItem value="Manual"><em>Manual</em></MenuItem>

                              <ListSubheader>Auto Generate</ListSubheader>
                              <MenuItem value="Intersection">Intersection</MenuItem>

                              <ListSubheader>Geography</ListSubheader>
                              {Object.keys(locationOptions || {}).map(type => (
                                <MenuItem key={type} value={type}>
                                  {type.charAt(0).toUpperCase() + type.slice(1)}
                                </MenuItem>
                              ))}

                              <ListSubheader>Global Lists</ListSubheader>
                              <MenuItem value="All Units" disabled={true}>All Units</MenuItem>
                              <MenuItem value="No Bookings" disabled={true}>No Bookings</MenuItem>
                              <MenuItem value="New Units" disabled={true}>New Units</MenuItem>
                            </Select>
                          </FormControl>
                          {editingStates[list.id]?.source &&
                            !['All Units', 'New Units', 'No Bookings', 'Manual'].includes(editingStates[list.id]?.source) && (
                            <div>
                              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginBottom: '8px' }}>
                                {editingStates[list.id]?.source_values?.map((value) => (
                                  <Chip
                                    key={value}
                                    label={getSourceValueDisplay(
                                        value,
                                        editingStates[list.id]?.source,
                                        unitLists
                                    )}
                                    onDelete={() => {
                                      setEditingStates(prev => ({
                                        ...prev,
                                        [list.id]: {
                                          ...prev[list.id],
                                          source_values: prev[list.id].source_values?.filter((v) => v !== value)
                                        }
                                      }));
                                    }}
                                    size="small"
                                  />
                                ))}
                              </div>
                              <FormControl variant="outlined" size="small" fullWidth>
                                <Select
                                  value=""
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    if (newValue) {
                                      if (editingStates[list.id]?.source_values?.includes(newValue)) {
                                        return;
                                      }

                                      setEditingStates(prev => ({
                                        ...prev,
                                        [list.id]: {
                                          ...prev[list.id],
                                          source_values: [
                                            ...(prev[list.id].source_values || []),
                                            newValue
                                          ]
                                        }
                                      }));
                                    }
                                  }}
                                  onClose={() => {
                                    // Clear search when closing dropdown
                                    setSourceValueSearch(prev => ({
                                      ...prev,
                                      [list.id]: ''
                                    }));
                                  }}
                                  disabled={isRefetching}
                                  displayEmpty
                                  // Add these props for searchable functionality
                                  onKeyDown={(e) => {
                                    if (e.key !== 'Escape') {
                                      e.stopPropagation();
                                    }
                                  }}
                                  renderValue={() => <em>Select a value</em>}
                                  MenuProps={{
                                    autoFocus: false,
                                    PaperProps: {
                                      style: { maxHeight: 400 }
                                    }
                                  }}
                                >
                                  <ListSubheader>
                                    <TextField
                                      size="small"
                                      autoFocus
                                      placeholder="Type to filter..."
                                      value={sourceValueSearch[list.id] || ''}
                                      onChange={(e) => {
                                        setSourceValueSearch(prev => ({
                                          ...prev,
                                          [list.id]: e.target.value
                                        }));
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Escape') {
                                          setSourceValueSearch(prev => ({
                                            ...prev,
                                            [list.id]: ''
                                          }));
                                        }
                                        e.stopPropagation();
                                      }}
                                      style={{ width: '100%', marginBottom: '8px' }}
                                    />
                                  </ListSubheader>
                                    <MenuItem value="">
                                      <em>Select a value</em>
                                    </MenuItem>
                                    <VirtualizedMenuItems
                                      items={getSourceOptions(
                                          editingStates[list.id]?.source,
                                          list.id,
                                          editingStates,
                                          unitLists,
                                          cohortData,
                                          locationOptions
                                      )}
                                      onSelect={(value) => {
                                        setEditingStates(prev => ({
                                          ...prev,
                                          [list.id]: {
                                            ...prev[list.id],
                                            source_values: [
                                              ...(prev[list.id].source_values || []),
                                              value
                                            ]
                                          }
                                        }));
                                      }}
                                      source={editingStates[list.id]?.source || undefined}
                                      unitLists={unitLists}
                                      cohortData={cohortData}
                                      searchTerm={sourceValueSearch[list.id] || ''}
                                    />
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <Tooltip title={list.description || 'No description'}>
                          <span>{list.name}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={list.admin_only ? 'Yes' : 'No'}
                          size="small"
                          color={list.admin_only ? 'primary' : 'default'}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title={formatCreatorTooltip(list.created_at)}>
                          <span>{formatCreatorName(list.creator)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={list.source_values ? list.source_values.join(', ') : ''}>
                          <span>{list.source}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{list.unit_count}</TableCell>
                    </>
                  )}
                  <TableCell>
                    <div style={{ display: 'flex', gap: '4px' }}>
                      {editingRows[list.id] ? (
                        <>
                          <Tooltip title={nameErrors[list.id] || "Save"}>
                            <span>
                              <IconButton
                                size="small"
                                onClick={() => handleSave(list)}
                                disabled={
                                  savingRows[list.id] || 
                                  isRefetching || 
                                  !!nameErrors[list.id] ||
                                  !editingStates[list.id]?.name.trim()
                                }
                              >
                                {savingRows[list.id] ? (
                                  <CircularProgress size={16} />
                                ) : (
                                  <Icon.Check fontSize="small" />
                                )}
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <IconButton
                              size="small"
                              onClick={() => handleCancel(list.id)}
                              disabled={savingRows[list.id] || isRefetching}
                            >
                              <Icon.Close fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title={
                              list.admin_only && !currentUser?.admin 
                                  ? "Only admins can edit admin-only lists" 
                                  : "Edit"
                          }>
                              <span>
                                  <IconButton
                                      size="small"
                                      onClick={() => handleEdit(list)}
                                      disabled={
                                          isRefetching ||
                                          refreshingRows[list.id] || 
                                          (list.admin_only && !currentUser?.admin)
                                      }
                                  >
                                      {isRefetching ? (
                                          <CircularProgress size={16} />
                                      ) : (
                                          <Icon.Edit fontSize="small" />
                                      )}
                                  </IconButton>
                              </span>
                          </Tooltip>
                          <Tooltip title={
                              list.admin_only && !currentUser?.admin 
                                  ? "Only admins can upload to admin-only lists" 
                                  : "Upload Units"
                          }>
                              <span>
                                  <IconButton
                                      size="small"
                                      onClick={() => handleUploadClick(list)}
                                      disabled={
                                          isRefetching || 
                                          uploadingUnits[list.id] || 
                                          (list.admin_only && !currentUser?.admin)
                                      }
                                  >
                                      {uploadingUnits[list.id] ? (
                                          <CircularProgress size={16} />
                                      ) : (
                                          <Icon.VerticalAlignTop fontSize="small" />
                                      )}
                                  </IconButton>
                              </span>
                          </Tooltip>
                          <Tooltip title="Download Units">
                            <IconButton
                              size="small"
                              onClick={() => handleDownload(list)}
                              disabled={isRefetching || downloadingRows[list.id]}
                            >
                              {downloadingRows[list.id] ? (
                                <CircularProgress size={16} />
                              ) : (
                                <Icon.VerticalAlignBottom fontSize="small" />
                              )}
                            </IconButton>
                          </Tooltip>
                          {list.source !== 'Manual' && (
                            <Tooltip title={
                                list.admin_only && !currentUser?.admin 
                                    ? "Only admins can refresh admin-only lists" 
                                    : formatDate(list.updated_at, list.created_at)
                            }>
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleRefreshRow(list)}
                                        disabled={
                                            isRefetching || 
                                            refreshingRows[list.id] || 
                                            (list.admin_only && !currentUser?.admin)
                                        }
                                    >
                                        {refreshingRows[list.id] ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <Icon.Replay fontSize="small" />
                                        )}
                                    </IconButton>
                                </span>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={filteredAndSortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Unit IDs</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Input
              type="file"
              // accept={".csv,.txt"}
              onChange={handleFileUpload}
              style={{ marginBottom: '16px' }}
            />
            <Typography variant="body2" color="textSecondary">
              Or paste unit IDs below (comma or newline separated):
            </Typography>
            <TextareaAutosize
              rowsMin={5}
              value={unitIdsText}
              onChange={(e) => setUnitIdsText(e.target.value)}
              style={{ width: '100%', padding: '8px' }}
              placeholder="Enter unit IDs here..."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUploadSubmit}
            color="primary"
            disabled={!unitIdsText.trim()}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default UnitListsTable;